import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useBoolean,
  useOutsideClick,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useCallback, useEffect, useRef, useState } from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import Calendar from 'components/Icons/Calendar';
import PlanPermission from 'container/PlanPermission';
import { TimeProperties } from 'models/response/model.interface';
import DatePickerInputButton from 'pages/ModelOverviewPage/DatePickerWithButton';
import TimeScalePermission from 'pages/PermissionComponent/TimeScalePermission';
import { getBlocks } from 'redux/ModelsSlice';
import { getSelectedBlockDimension, modelDetailStatus } from 'redux/PlanPageSlice';
import { getCompareScenario } from 'redux/ScenarioSlice';
import { getUserSubscriptionLimits } from 'redux/UserSlice';
import { getDashboard, setLoading } from 'redux/DashboardSlice';
import { AppDispatch } from 'utils/GlobalHelpers';

export interface Properties {
  planPermission: boolean;
  rangeSelected: (start: string, end: string) => void;
}

const TimeRangeFilter = ({ planPermission, rangeSelected }: Properties) => {
  const dispatch: AppDispatch = useDispatch();
  const { modelId } = useParams();
  const [timescale, setTimescale] = useState('Y');
  const [maxValue, setMaxValue] = useState(3);
  const [periodAmount, setPeriodAmount] = useState('3');
  const [endIn, setEndIn] = useState<Date | any>(new Date());
  const [isPermissionTimescaleModal, setIsPermissionTimescaleModal] = useState(false);
  const [startIn, setStartIn] = useState<Date | any>(new Date());
  const model = useSelector(getBlocks);
  const modelStatus = useSelector(modelDetailStatus);
  const UserSubscriptionLimitData = useSelector(getUserSubscriptionLimits);
  const reference = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [timeScalePopover, setTimeScalePopover] = useBoolean();
  const selectedBlockDimension = useSelector(getSelectedBlockDimension);
  const [filterStartDate, setFilterStartDate] = useState<Date | any>();
  const [filterEndDate, setFilterEndDate] = useState<Date | any>();
  const formatTimeProperties = (properties: TimeProperties) => {
    return { ...properties, time_granularity: properties?.time_granularity ?? 'M' };
  };
  const compareScenario = useSelector(getCompareScenario);
  const properties = formatTimeProperties(model?.time_properties);
  const timeGranualityLimit =
    UserSubscriptionLimitData?.subscription_features_limit?.model_timescale;
  const dashboard = useSelector(getDashboard);
  const formatFilterDate = () => {
    if (filterStartDate && filterEndDate) {
      const startDate = new Date(filterStartDate).toDateString();
      setFilterStartDate(
        timescale === 'Y' ? dayjs(startDate).format('YYYY') : dayjs(startDate).format('DD MMM YY'),
      );
      const endDate = new Date(filterEndDate).toDateString();
      setFilterEndDate(
        timescale === 'Y' ? dayjs(endDate).format('YYYY') : dayjs(endDate).format('DD MMM YY'),
      );
    }
  };

  useEffect(() => {
    if (timeGranualityLimit === null) {
      setMaxValue(-1);
    } else {
      if (timescale.toLowerCase() === 'm') {
        setMaxValue(timeGranualityLimit);
      }
      if (timescale.toLowerCase() === 'y') {
        setMaxValue(timeGranualityLimit / 12);
      }
    }
  }, [timescale, timeGranualityLimit]);

  useEffect(() => {
    formatFilterDate();
  }, [filterStartDate, filterEndDate]);
  useEffect(() => {
    if (properties) {
      const timeGranularity = properties?.time_granularity ?? 'M';
      setTimescale(timeGranularity.toUpperCase());
      const value = Number.isNaN(properties?.plan_duration_periods)
        ? timeGranularity.toLowerCase() === 'm'
          ? 36
          : 3
        : Number(properties?.plan_duration_periods);
      setPeriodAmount(value.toString());
      setStartIn(properties?.starting_period ? new Date(properties?.starting_period) : null);
      setFilterStartDate(
        properties?.starting_period ? new Date(properties?.starting_period) : null,
      );
    }
    if (properties && properties?.starting_period) {
      const endDate = new Date(properties?.starting_period);
      const timeGranularity = properties?.time_granularity ?? 'M';

      const value = Number.isNaN(properties?.plan_duration_periods)
        ? timeGranularity.toLowerCase() === 'm'
          ? 36
          : 3
        : Number(properties?.plan_duration_periods);
      const targetValue = timeGranularity.toLowerCase() === 'm' ? value : value * 12;
      const dateEnd = endDate.setMonth(endDate.getMonth() + targetValue);
      setEndIn(new Date(dateEnd));
      setFilterEndDate(new Date(dateEnd));
    } else {
      setEndIn(null);
    }
  }, [modelStatus]);

  const calculateShowData = (data: any) => {
    if (timescale === 'Y') {
      return data?.getFullYear();
    }
    return `${data?.toLocaleDateString('en-US', {
      month: 'short',
    })} ${data?.getFullYear().toString().slice(-2)}`;
  };

  const getExactDate = (dateString: string) => {
    if (properties?.time_granularity?.toLowerCase() === 'y') return dateString;
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    const dd = new Date(dateString);
    const month = months[dd.getMonth()]; // Get the month name from the array
    const year = dd.getFullYear().toString().slice(-2); // Get the last two digits of the year
    const newString = `${month}-${year}`;
    return newString;
  };

  const convertToDate = (input: string) => {
    const months: any = {
      Jan: 0,
      Feb: 1,
      Mar: 2,
      Apr: 3,
      May: 4,
      Jun: 5,
      Jul: 6,
      Aug: 7,
      Sep: 8,
      Oct: 9,
      Nov: 10,
      Dec: 11,
    };

    // Validate the input format
    const match = input.match(/^([A-Za-z]{3})-(\d{2})$/);
    if (!match) return null;

    const [, month, yearSuffix] = match;
    const monthIndex = months[month];
    if (monthIndex === undefined) return null;

    // Convert the year suffix to a full year
    const currentYear = new Date().getFullYear();
    const century = Math.floor(currentYear / 100) * 100;
    const year = century + Number.parseInt(yearSuffix, 10);

    // Create the date object and format it
    const date = new Date(year, monthIndex, 1);
    return date;
  };

  useEffect(() => {
    if (properties && dashboard?.id) {
      dispatch(setLoading(true));
      const object = JSON.parse(localStorage.getItem('timeFilter') || '{}');
      if (object && dashboard?.id && object[dashboard?.id]) {
        const { startDatePlanPage, endDatePlanPage } = object[dashboard?.id];
        setStartIn(convertToDate(startDatePlanPage));
        setEndIn(convertToDate(endDatePlanPage));
        rangeSelected(startDatePlanPage, endDatePlanPage);
      } else {
        const timeGranularity = properties?.time_granularity ?? 'M';
        setTimescale(timeGranularity.toUpperCase());
        const value = Number.isNaN(properties?.plan_duration_periods)
          ? timeGranularity.toLowerCase() === 'm'
            ? 36
            : 3
          : Number(properties?.plan_duration_periods);
        setPeriodAmount(value.toString());
        setStartIn(properties?.starting_period ? new Date(properties?.starting_period) : null);
        setFilterStartDate(
          properties?.starting_period ? new Date(properties?.starting_period) : null,
        );

        const endDate = new Date(properties?.starting_period);

        const targetValue = timeGranularity.toLowerCase() === 'm' ? value : value * 12;
        const dateEnd = endDate.setMonth(endDate.getMonth() + targetValue);
        setEndIn(new Date(dateEnd));
        setFilterEndDate(new Date(dateEnd));
        rangeSelected(
          getExactDate(new Date(properties.starting_period).toLocaleString()),
          getExactDate(new Date(dateEnd).toLocaleString()),
        );
      }
      dispatch(setLoading(false));
    }
  }, [dashboard?.id]);

  const applyPlanPageFilter = (startDatePlanPage: string, endDatePlanPage: string) => {
    rangeSelected(startDatePlanPage, endDatePlanPage);
    const object = JSON.parse(localStorage.getItem('timeFilter') || '{}');
    if (dashboard) {
      localStorage.setItem(
        'timeFilter',
        JSON.stringify({
          ...object,
          [dashboard?.id]: { startDatePlanPage, endDatePlanPage },
        }),
      );
    }
  };

  const changeTimeInput = useCallback(
    (time: string, amount: string, startDate: Date | null, endDate?: Date | null) => {
      const requestObject: any = {
        model_id: Number(modelId),
        request_body: {
          time_granularity: time,
          plan_duration_periods: amount,
        },
      };
      if (endDate) {
        const endInDate = endDate.toDateString();
        requestObject.request_body.ending_period =
          time === 'Y' ? dayjs(endInDate).format('YYYY') : dayjs(endInDate).format('DD MMM YY');
      }
      if (startDate !== null) {
        const startInDate = startDate.toDateString();
        requestObject.request_body.starting_period =
          time === 'Y' ? dayjs(startInDate).format('YYYY') : dayjs(startInDate).format('DD MMM YY');
      }
      const endDatePlanPage = getExactDate(requestObject.request_body.ending_period);
      const startDatePlanPage = getExactDate(requestObject.request_body.starting_period);
      applyPlanPageFilter(startDatePlanPage, endDatePlanPage);
    },
    [modelId, filterStartDate, filterEndDate, compareScenario, selectedBlockDimension],
  );

  const onChangePeriod = async (valueDiff: any) => {
    const newValue = valueDiff;
    if (Number(newValue) <= maxValue) {
      setPeriodAmount(newValue);
    } else if (maxValue === -1) {
      setPeriodAmount(newValue);
    } else {
      setIsPermissionTimescaleModal(true);
      setTimeout(() => {
        const timeGranularity = properties?.time_granularity ?? 'M';
        const value = Number.isNaN(properties?.plan_duration_periods)
          ? timeGranularity.toLowerCase() === 'm'
            ? 36
            : 3
          : Number(properties?.plan_duration_periods);
        setPeriodAmount(value.toString());
      }, 1000);
      setTimeout(() => {
        setIsPermissionTimescaleModal(false);
      }, 5000);
    }
  };

  const startDateChange = (startDate: Date) => {
    if (timescale.toLowerCase() === 'm') {
      const monthsApart =
        (endIn.getFullYear() - startDate.getFullYear()) * 12 +
        (endIn.getMonth() - startDate.getMonth());
      setPeriodAmount(`${monthsApart}`);
      onChangePeriod(monthsApart);
      return `${monthsApart}`;
    }
    const yearDifference = endIn.getFullYear() - startDate.getFullYear();
    setPeriodAmount(yearDifference.toString());
    onChangePeriod(`${yearDifference}`);
    return `${yearDifference}`;
  };

  const endDateChange = (endDate: Date) => {
    if (timescale.toLowerCase() === 'm') {
      const monthsApart =
        (endDate.getFullYear() - startIn.getFullYear()) * 12 +
        (endDate.getMonth() - startIn.getMonth());
      setPeriodAmount(`${monthsApart}`);
      onChangePeriod(monthsApart);
      return `${monthsApart}`;
    }
    const yearDifference = endDate.getFullYear() - startIn.getFullYear();
    setPeriodAmount(yearDifference.toString());
    onChangePeriod(`${yearDifference}`);
    return `${yearDifference}`;
  };

  const onSelectStartDate = async (date: Date) => {
    setStartIn(date);
    const diff = startDateChange(date);
    onChangePeriod(Number(diff));
    changeTimeInput(timescale, diff, date, endIn);
  };

  const onSelectEndDate = useCallback(
    (date: Date) => {
      setEndIn(date);
      const diff = endDateChange(date);
      onChangePeriod(Number(diff));
      changeTimeInput(timescale, diff, startIn, date);
    },
    [changeTimeInput, periodAmount, timescale],
  );

  const handleClearSelection = () => {
    setStartIn(null);
  };

  useOutsideClick({
    ref: reference,
    handler: () => {
      setTimeScalePopover.off();
    },
  });
  return (
    <Flex
      ps={0}
      gap={1}
      mr={2}
      backgroundColor={'#5A58AB'}
      padding={'.6rem 1.2rem'}
      borderRadius={'2rem'}
    >
      <Popover
        placement='bottom-start'
        closeOnBlur={false}
        isOpen={timeScalePopover}
        onOpen={setTimeScalePopover.on}
        onClose={setTimeScalePopover.off}
        isLazy
        lazyBehavior='keepMounted'
      >
        <Box ref={reference}>
          <PopoverTrigger>
            <Flex cursor={'pointer'} alignItems={'center'}>
              <Calendar
                style={{
                  marginRight: '.2rem',
                }}
                width={8}
                height={6}
                color='#69B4B8'
              />
              <ChevronDownIcon w={5} h={5} />
              <Text ml={1} size={'Roboto-14'}>
                {calculateShowData(startIn)}
              </Text>
              <Text fontSize={'14px'} mx={1}>
                -
              </Text>
              <Text size={'Roboto-14'}>{calculateShowData(endIn)}</Text>
            </Flex>
          </PopoverTrigger>
          <PopoverContent
            filter='drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.25))'
            bg='white'
            border='none'
            mt={3}
            borderRadius={'8px'}
          >
            <PopoverBody ps={4}>
              <Box pt={4} pb={2}>
                <Text color='#6562CF' fontSize={'xs'} fontWeight={'600'}>
                  Timescale
                </Text>
              </Box>
              <Flex justify={'start'} align={'center'} pb={3}>
                <DatePickerInputButton
                  clearSection={false}
                  clearDateInput={handleClearSelection}
                  selected={startIn}
                  onChange={onSelectStartDate}
                  timescale={timescale}
                  isDisabled={planPermission}
                  minDate={new Date(filterStartDate)}
                  maxDate={new Date(endIn)}
                />
                <Flex px={2}>
                  <BsArrowRight color={'black'} style={{ width: '20px', height: '20px' }} />
                </Flex>
                <DatePickerInputButton
                  clearSection={false}
                  clearDateInput={handleClearSelection}
                  selected={endIn}
                  onChange={onSelectEndDate}
                  timescale={timescale}
                  isDisabled={planPermission}
                  minDate={new Date(startIn)}
                  maxDate={new Date(filterEndDate)}
                />
              </Flex>
              <Text color={'#8B8AAB'} fontSize={'12px'} ps={1}>
                {timescale === 'M'
                  ? `${Number(periodAmount) + 1} months`
                  : `${Number(periodAmount) + 1} years`}
              </Text>
              {isPermissionTimescaleModal && (
                <TimeScalePermission maxValue={maxValue} timescale={timescale} />
              )}
            </PopoverBody>
          </PopoverContent>
        </Box>
      </Popover>
    </Flex>
  );
};

export default PlanPermission(TimeRangeFilter);
