import { useSelector } from 'react-redux';

import {
  getDashboardTableData,
  getDashboardTableExpandKey,
  getDashboardTableFilter,
  getDashboardTableHeaderData,
  getDashboardChartId,
} from 'redux/DashboardSlice';

import DashboardTable from './DashboardTable';

const TablePreview = () => {
  const tableData = useSelector(getDashboardTableData);
  const expandkey = useSelector(getDashboardTableExpandKey);
  const chartId = useSelector(getDashboardChartId);

  const tableFilter = useSelector(getDashboardTableFilter);
  const tableHeaderData = useSelector(getDashboardTableHeaderData);
  const tableFormattedData: any = {
    tableData,
    tableFilter,
    expandkey,
    tableHeaderData,
    chartId,
    isPreview: true,
  };
  return <DashboardTable {...tableFormattedData} />;
};

export default TablePreview;
