/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
import 'react-grid-layout/css/styles.css';
import './index.css';
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Skeleton, Image, Flex } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { PDFExport } from '@progress/kendo-react-pdf';
import debounce from 'lodash.debounce';

import {
  fetchAllCharts,
  // fetchDashboardGraph,
  getAllChartIds,
  getAllChartData,
  getDashboardWidgetType,
  handleAddAllChartData,
  resetDashboard,
  fetchDashboardChart,
  FetchModelBlocks,
  fetchDashboardGraph,
  addAllDashboardTableData,
  getDashboardSelectedScenario,
  selectDashboardScenario,
  fetchDashboardID,
  createDashboardID,
  getModelDashboardId,
  updatelayout,
  getDashboard,
  getLoading,
} from 'redux/DashboardSlice';
import { AppDispatch, isNullOrUndefinedOrEmpty } from 'utils/GlobalHelpers';
import FullScreenLoader from 'components/FullScreenLoader';
import formatIndicatorTableData from 'utils/FormatTable';
import { fetchAllScenarios, getAllScenarios } from 'redux/ScenarioSlice';
import { getBlocks } from 'redux/ModelsSlice';
import { getUserData } from 'redux/UserSlice';

import EmptyDashboard from './EmptyDashboard';
import DashboardModal from './dashboardModal';
import DashboardingHeader from './Header';
import LayoutComponent from './LayoutComponent';

const Dashboarding = () => {
  const targetReference = useRef<any>();
  const rowHeight = 65;
  const dispatch: AppDispatch = useDispatch();
  const allChartData: any = useSelector(getAllChartData);
  const allChartIds = useSelector(getAllChartIds);
  const { modelId } = useParams();
  const scenarios = useSelector(getAllScenarios);
  const dashboardWidgetType = useSelector(getDashboardWidgetType);
  const modelDashboardId = useSelector(getModelDashboardId);
  const dashboard = useSelector(getDashboard);
  const currentUser = useSelector(getUserData);
  const selectedScenario = useSelector(getDashboardSelectedScenario);
  const model = useSelector(getBlocks);
  const [currentBreakpoint, setCurrentBreakpoint] = useState('lg');
  // const layoutsData: any = Object.values(allChartData);
  const scenarioId = selectedScenario?.id;
  const [loader, setLoader] = useState(true);
  const [pdfExport, setPDFExport] = useState(false);
  const ResponsiveReactGridLayout = useMemo(() => WidthProvider(Responsive), []);
  const isLoading = useSelector(getLoading);

  useEffect(() => {
    return () => {
      dispatch(resetDashboard());
    };
  }, []);

  useEffect(() => {
    if (modelId && model) {
      const yr = new Date(model?.time_properties?.starting_period).getUTCFullYear().toString();
      const fiscalStart = `${model?.time_properties?.fy_start_month}-${yr[2]}${yr[3]}`;
      dispatch(fetchAllScenarios(modelId)).then(() => {
        dispatch(fetchDashboardID({ model_id: modelId })).then((response) => {
          if (!response.payload?.id) {
            dispatch(
              createDashboardID({
                request_body: {
                  name: 'string',
                  model_id: Number(modelId),
                  fiscal_start_month:
                    model?.time_properties?.time_granularity === 'M'
                      ? fiscalStart
                      : `Jan-${yr[2]}${yr[3]}`,
                  description: 'string',
                },
              }),
            );
          }
        });
      });
      dispatch(FetchModelBlocks({ modelId: Number(modelId) }));
    }
  }, [modelId]);

  const refetchAllCharts = () => {
    dispatch(fetchAllCharts(modelDashboardId)).then((data: any) => {
      if (data?.payload?.length === 0) {
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  };

  useEffect(() => {
    if (modelDashboardId) {
      refetchAllCharts();
    }
  }, [modelDashboardId]);

  useEffect(() => {
    if (!selectedScenario) {
      const baseScenario = scenarios.find((item: any) => item.is_base);
      dispatch(selectDashboardScenario(baseScenario));
    }
  }, [scenarios]);

  useEffect(() => {
    if (allChartIds?.length > 0 && scenarioId) {
      allChartIds.map((item: any) => {
        // eslint-disable-next-line default-case
        switch (item.instrument_type) {
          case 'kpi': {
            dispatch(
              handleAddAllChartData({
                id: item.id,
                shimmer: true,
                failed: false,
                type: 'kpi',
                instrument_type: 'kpi',
              }),
            );
            dispatch(fetchDashboardChart(item.id))
              .then((data: any) => {
                setLoader(false);
                if (!data.error) {
                  const updatedPayload = JSON.parse(JSON.stringify(data.payload));
                  updatedPayload.shimmer = false;
                  updatedPayload.instrument_type = 'kpi';

                  dispatch(handleAddAllChartData(updatedPayload));
                } else {
                  dispatch(
                    handleAddAllChartData({
                      id: item.id,
                      shimmer: false,
                      failed: true,
                      instrument_type: 'kpi',
                    }),
                  );
                }
              })
              .catch(() => {
                dispatch(
                  handleAddAllChartData({
                    id: item.id,
                    shimmer: false,
                    failed: true,
                    instrument_type: 'kpi',
                  }),
                );
              });
            break;
          }
          case 'table': {
            dispatch(
              handleAddAllChartData({
                id: item.id,
                shimmer: true,
                failed: false,
                type: 'table',
                instrument_type: 'table',
              }),
            );
            dispatch(fetchDashboardGraph({ chartId: item.id, scenarioId, requestBody: [] }))
              .then((data: any) => {
                setLoader(false);
                if (!data.error) {
                  const layoutData = {
                    id: item.id,
                    shimmer: false,
                    instrument_type: 'table',
                    name: item.name,
                    dashboard_id: item.dashboard_id,
                  };
                  dispatch(addAllDashboardTableData({ ...data.payload, ...layoutData }));
                  const formattedTableData = formatIndicatorTableData({
                    indicatorTableData: data?.payload,
                    expandKey: '',
                    tableFilter: 'M',
                  });

                  dispatch(
                    handleAddAllChartData({
                      ...formattedTableData,
                      ...layoutData,
                      ...data.payload,
                    }),
                  );
                } else {
                  dispatch(
                    handleAddAllChartData({
                      id: item.id,
                      shimmer: false,
                      failed: true,
                      instrument_type: 'table',
                    }),
                  );
                }
              })
              .catch(() => {
                dispatch(
                  handleAddAllChartData({
                    id: item.id,
                    shimmer: false,
                    failed: true,
                    instrument_type: 'table',
                  }),
                );
              });
            break;
          }
          case 'pie': {
            dispatch(
              handleAddAllChartData({ id: item.id, shimmer: true, failed: false, type: 'pie' }),
            );
            dispatch(fetchDashboardGraph({ chartId: item.id, scenarioId, requestBody: [] }))
              .then((response: any) => {
                setLoader(false);
                if (!response.error) {
                  const layoutData = {
                    id: item.id,
                    instrument_type: 'pie',
                    shimmer: false,
                  };
                  dispatch(handleAddAllChartData({ ...response.payload, ...layoutData }));
                } else {
                  dispatch(handleAddAllChartData({ id: item.id, shimmer: false, failed: true }));
                }
              })
              .catch(() => {
                dispatch(handleAddAllChartData({ id: item.id, shimmer: false, failed: true }));
              });
            break;
          }
          case 'bar': {
            dispatch(
              handleAddAllChartData({
                id: item.id,
                shimmer: true,
                failed: false,
                type: 'bar',
                instrument_type: 'bar',
              }),
            );
            dispatch(fetchDashboardGraph({ chartId: item.id, scenarioId, requestBody: [] }))
              .then((response: any) => {
                if (!response.error) {
                  setLoader(false);
                  const layoutData = {
                    id: item.id,
                    i: item.id,
                    instrument_type: 'bar',
                    shimmer: false,
                  };
                  dispatch(handleAddAllChartData({ ...response.payload, ...layoutData }));
                } else {
                  dispatch(
                    handleAddAllChartData({
                      id: item.id,
                      shimmer: false,
                      failed: true,
                      instrument_type: 'bar',
                    }),
                  );
                }
              })
              .catch(() => {
                dispatch(
                  handleAddAllChartData({
                    id: item.id,
                    shimmer: false,
                    failed: true,
                    instrument_type: 'bar',
                  }),
                );
              });
            break;
          }
          case 'line': {
            dispatch(
              handleAddAllChartData({
                id: item.id,
                shimmer: true,
                failed: false,
                type: 'line',
                instrument_type: 'line',
              }),
            );
            dispatch(fetchDashboardGraph({ chartId: item.id, scenarioId, requestBody: [] }))
              .then((response: any) => {
                if (!response.error) {
                  setLoader(false);
                  const layoutData = {
                    id: item.id,
                    instrument_type: 'line',
                    shimmer: false,
                  };
                  dispatch(handleAddAllChartData({ ...response.payload, ...layoutData }));
                } else {
                  dispatch(
                    handleAddAllChartData({
                      id: item.id,
                      shimmer: false,
                      failed: true,
                      instrument_type: 'line',
                    }),
                  );
                }
              })
              .catch(() => {
                dispatch(
                  handleAddAllChartData({
                    id: item.id,
                    shimmer: false,
                    failed: true,
                    instrument_type: 'line',
                  }),
                );
              });
            break;
          }
        }
      });
    }
  }, [allChartIds, scenarioId]);

  const handleModify = debounce((currentLayout: any, allLayouts: any) => {
    if (modelId && currentUser && modelDashboardId && dashboard) {
      const isLGMD = currentBreakpoint === 'md' || currentBreakpoint === 'lg';
      const isSmallDevice =
        currentBreakpoint === 'xss' || currentBreakpoint === 'xs' || currentBreakpoint === 'sm';

      let object = { [currentBreakpoint]: currentLayout };

      if (isLGMD) {
        object = isLGMD
          ? { lg: currentLayout, md: currentLayout }
          : { [currentBreakpoint]: currentLayout };
      } else if (isSmallDevice) {
        object = { xss: currentLayout, sm: currentLayout, xs: currentLayout };
      }

      dispatch(
        updatelayout({
          instrument_layout: { ...allLayouts, ...object },
          id: modelDashboardId,
          description: dashboard?.description,
          name: dashboard?.name,
          user_id: dashboard?.user_id,
          model_id: dashboard?.model_id,
          created_at: dashboard?.created_at,
          fiscal_start_month: dashboard?.fiscal_start_month,
          updated_at: dashboard?.updated_at,
        }),
      ).then(() => {
        dispatch(fetchDashboardID({ model_id: modelId }));
      });
    }
  }, 1000);

  const handleBreakpointChange = (newBreakpoint: string) => {
    setCurrentBreakpoint(newBreakpoint); // Optional: Update state to track the current breakpoint
  };

  if (loader || isLoading) {
    return (
      <Box height='100%' width='100%' p={1}>
        <FullScreenLoader
          style={{ position: 'absolute' }}
          height='100%'
          width='calc(100vw - 4.5rem)'
        />
      </Box>
    );
  }

  const onExport = () => {
    setPDFExport(true);
    try {
      if (targetReference.current) {
        targetReference.current.save();
        setPDFExport(false);
      }
    } catch {
      setPDFExport(false);
      alert('Export Failed !!');
    }
  };

  const getValidatedLayout = (widget: any, widgetLayout: any, defaultMinH: number) => {
    const isKPI = widget.instrument_type === 'kpi' || widget.instrument_type === 'table';
    const height = !isNullOrUndefinedOrEmpty(widgetLayout?.h) ? widgetLayout.h : isKPI ? 3 : 5;
    console.log(isKPI, widget, height, defaultMinH, widgetLayout);
    let newX = 0;
    let newY = 0;
    if (!widgetLayout) {
      const cols = 12; // Number of columns in the grid

      const newItemWidth = !isNullOrUndefinedOrEmpty(widgetLayout?.w) ? widgetLayout.w : 3; // Width of the new item (in grid columns)
      const newItemHeight = height > defaultMinH ? height : defaultMinH; // Height of the new item (in grid rows)

      // Calculate the next available position for the new item
      const getNextAvailablePosition = () => {
        if (
          !dashboard ||
          !dashboard?.instrument_layout ||
          !dashboard?.instrument_layout[currentBreakpoint]
        ) {
          return { x: 0, y: 0 }; // Fallback if any of the required data is undefined
        }
        const currentlayout = dashboard?.instrument_layout[currentBreakpoint] || {
          [currentBreakpoint]: {
            x: 0,
            y: 0,
            h: height,
            w: newItemWidth,
          },
        };
        // eslint-disable-next-line no-plusplus
        for (let y = 0; y < Number.POSITIVE_INFINITY; y++) {
          for (let x = 0; x <= cols - newItemWidth; x++) {
            // Check if the space is available
            const isSpaceOccupied = currentlayout.some(
              (item: any) =>
                x < item.x + item.w &&
                x + newItemWidth > item.x &&
                y < item.y + item.h &&
                y + newItemHeight > item.y,
            );
            if (!isSpaceOccupied) {
              return { x, y };
            }
          }
        }
        return { x: 0, y: 0 }; // Fallback
      };

      const { x, y } = getNextAvailablePosition();
      newX = x;
      newY = y;
    }

    return {
      ...widget,
      x: !isNullOrUndefinedOrEmpty(widgetLayout?.x) ? widgetLayout.x : newX ?? 0,
      y: !isNullOrUndefinedOrEmpty(widgetLayout?.y)
        ? widgetLayout.y
        : newY ?? Number.POSITIVE_INFINITY,
      w: !isNullOrUndefinedOrEmpty(widgetLayout?.w) ? widgetLayout.w : 3,
      // h: !isNullOrUndefinedOrEmpty(widgetLayout?.h)
      //   ? widgetLayout.h
      //   : widget.h < defaultMinH
      //   ? defaultMinH
      //   : widget.h ?? defaultMinH,
      h: height > defaultMinH ? height : defaultMinH,
      minW: 2,
      minH: defaultMinH,
      i: widget.id,
    };
  };

  return (
    <>
      {!loader && !isLoading && (
        <Box height={'100%'}>
          <DashboardingHeader
            onExport={onExport}
            pdfExport={pdfExport}
            refetchAllCharts={refetchAllCharts}
          />
          <Box
            className='scroll'
            // height={'calc(100% - 53px)'}
            mt={2}
            mx={'-20px'}
          >
            <PDFExport
              paperSize='auto'
              margin='1cm'
              ref={targetReference}
              fileName={`${model?.name}_DashboardExport.pdf`}
            >
              {Object.keys(allChartData).length > 0 && dashboard ? (
                <ResponsiveReactGridLayout
                  resizeHandles={['ne', 'nw', 'se', 'sw']}
                  useCSSTransforms={true}
                  className='dashboard-grid-container'
                  draggableHandle='.dashboard-drag-header'
                  allowOverlap={false}
                  onLayoutChange={handleModify}
                  onBreakpointChange={handleBreakpointChange}
                  verticalCompact={true}
                  layouts={dashboard?.instrument_layout || {}}
                  preventCollision={false}
                  rowHeight={rowHeight}
                  autoSize={true}
                  margin={{
                    lg: [12, 12],
                    md: [12, 12],
                    sm: [12, 12],
                    xs: [12, 12],
                    xxs: [12, 12],
                  }}
                  breakpoints={{
                    lg: 768, // Large screens (tablet size and up)
                    sm: 0, // Small devices
                  }}
                  cols={{
                    lg: 12, // 12 columns for large screens
                    sm: 1, // Single column for small screens
                  }}
                >
                  {Object.keys(allChartData)
                    .filter((key: any) => allChartData[key]?.instrument_type !== 'pie')
                    ?.map((key: any, index: any) => {
                      const widget = allChartData[key];
                      const minH =
                        widget.instrument_type === 'kpi' || widget.instrument_type === 'table'
                          ? 3
                          : 5;
                      const widgetLayout: any =
                        dashboard?.instrument_layout &&
                        dashboard?.instrument_layout[currentBreakpoint]
                          ? dashboard?.instrument_layout[currentBreakpoint]?.find((item: any) => {
                              return item.i === widget.id;
                            })
                          : null;

                      return widget?.shimmer ? (
                        <Skeleton
                          variant={'pulse'}
                          startColor='white'
                          endColor='#CCD4E8'
                          h={'-webkit-fill-available'}
                          key={key}
                          data-grid={getValidatedLayout(widget, widgetLayout, minH)}
                        ></Skeleton>
                      ) : (
                        <Box
                          className='dashboard-item page-break-before'
                          key={key}
                          data-grid={getValidatedLayout(widget, widgetLayout, minH)}

                          // if this doesn't work use gridData
                        >
                          <LayoutComponent widget={widget} />
                        </Box>
                      );
                    })}
                </ResponsiveReactGridLayout>
              ) : (
                <>
                  <EmptyDashboard />
                </>
              )}
            </PDFExport>
          </Box>
        </Box>
      )}

      {dashboardWidgetType !== 0 && <DashboardModal />}
    </>
  );
};

export default Dashboarding;
