import { Box, Flex, Table, TableContainer, Tbody, Th, Thead, Tr } from '@chakra-ui/react';
import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ArrowLeftTable from 'components/Icons/ArrowLeftTable';
import ArrowRightTable from 'components/Icons/ArrowRightTable';
import {
  getAllDashboardTableData,
  // getKpiChartData,
  handleAddAllChartData,
  setDashboardTableExpandKey,
  updateInstrumentType,
  getAllChartData,
  // getDashboardTableData,
  // getDashboardTableExpandKey,
  // getDashboardTableFilter,
  // getDashboardTableHeaderData,
  // setDashboardTableExpandKey,
} from 'redux/DashboardSlice';
import { AppDispatch } from 'utils/GlobalHelpers';
import { MONTH_NAMES } from 'projectConstants';
import formatIndicatorTableData from 'utils/FormatTable';

import TimeRangeFilter from '../TimeRangeFilter';
import EditableTitle from '../EditableTitle';

import DashboardTableRow from './DashboardTableRow';
// import DimensionItemFilter from './DimensionItemFilter';
import TimeAggregationFilter from './TimeAggregationFilter';

const DashboardTable = ({
  tableData,
  expandkey,
  tableFilter,
  tableHeaderData,
  isDashboardLayout,
  chartId,
  isPreview,
  chartData,
}: any) => {
  const dispatch: AppDispatch = useDispatch();
  const allChartData = useSelector(getAllChartData);
  const allTable = useSelector(getAllDashboardTableData);
  const [actualTableHeaders, setActualTableHeaders] = useState<any>(null);
  const appliedFilters: any = [];
  const [actualTableData, setActualTableData] = useState<Array<any>>([]);
  // const [dataHadActualValue, setDataHadActualValue] = useState<boolean>(false);
  const startDateObject = new Date('2024-05-08');
  const yearFormat = `FY${startDateObject.getFullYear().toString().slice(-2)}`;
  let timeAggregation = 'm';
  const quarterFormat = `Q${Math.ceil((startDateObject.getMonth() + 1) / 3)}-${String(
    yearFormat,
  ).slice(-2)}`;
  const parentReference = useRef() as React.MutableRefObject<HTMLDivElement>;

  timeAggregation = '';
  const monthFormat = `${MONTH_NAMES[startDateObject.getMonth()]}-${String(yearFormat).slice(-2)}`;
  const dimensionFilters = (payload: any) => {
    const dimensionTableData = payload || [];
    if (
      appliedFilters?.length > 0 &&
      dimensionTableData?.length > 0 &&
      dimensionTableData?.[0]?.dims?.[0]
    ) {
      const hasDims = dimensionTableData[0]?.dims[0]?.Time;
      const dimensionIndex = appliedFilters?.findIndex((data: any) => {
        const object = Object.keys(data);
        return object[0] === hasDims;
      });
      const results = dimensionTableData?.map((data: any) => {
        if (data?.dims && data?.dims?.length && hasDims && appliedFilters[dimensionIndex]) {
          const filteredData = data?.dim_df?.filter((item: any) => {
            return appliedFilters[dimensionIndex][hasDims]?.includes(item?.Time?.trim());
          });
          return { ...data, dim_df: filteredData };
        }
        return data;
      });
      setActualTableData(results);
    } else {
      setActualTableData(payload);
    }
  };

  const targetDate = () => {
    if (tableFilter === 'M') {
      return monthFormat;
    }
    if (tableFilter === 'Q') {
      return quarterFormat;
    }
    return yearFormat;
  };
  useEffect(() => {
    if (tableHeaderData) {
      const actualDate = targetDate();
      const actualTableHeaderNew = [];
      let afterFlag = false;
      // setDataHadActualValue(false);
      for (let index = tableHeaderData.length - 1; index >= 0; index -= 1) {
        const item = JSON.parse(JSON.stringify(tableHeaderData[index]));
        if (item.Time === `${actualDate}`) {
          afterFlag = true;
          // setDataHadActualValue(true);
        }
        item.isActualValue = afterFlag;
        actualTableHeaderNew.push(item);
      }
      setActualTableHeaders(actualTableHeaderNew.reverse());
    }
  }, [tableFilter, tableHeaderData]);

  useEffect(() => {
    if (tableData) {
      const actualTableDataNew = [];
      const actualDate = targetDate();
      // eslint-disable-next-line unicorn/no-for-loop
      for (let index = 0; index < tableData.length; index += 1) {
        const item = JSON.parse(JSON.stringify(tableData[index]));
        const totalValues = [];
        let afterFlag = false;
        for (let indexOne = item.total.length - 1; indexOne >= 0; indexOne -= 1) {
          const itemValue = JSON.parse(JSON.stringify(item.total[indexOne]));
          if (itemValue.Time === `${actualDate}`) {
            afterFlag = true;
          }
          itemValue.isActualValue = afterFlag;
          totalValues.push(itemValue);
        }
        item.total = totalValues.reverse();
        item.dim_df = item.dim_df?.map((dimValue: any) => {
          const dimValues = [];
          let flagNew = false;

          if (item?.dim_df) {
            for (let indexTwo = item.dim_df.length - 1; indexTwo >= 0; indexTwo -= 1) {
              const itemValue = JSON.parse(JSON.stringify(item.dim_df[indexTwo]));
              if (itemValue.Time === `${actualDate}`) {
                flagNew = true;
              }
              itemValue.isActualValue = flagNew;
              dimValues.push(itemValue);
            }
          }

          item.dim_df = dimValues.reverse();
          return dimValue;
        });
        actualTableDataNew.push(item);
      }
      dimensionFilters(actualTableDataNew);
    }
  }, [tableFilter, tableData]);

  const expand = (value: any) => {
    if (isDashboardLayout && value.isShowExpandable && timeAggregation !== 'Y') {
      const updatedData = allTable[chartId];
      const layoutData = {
        id: updatedData.id,
        x: Number(updatedData.x),
        y: Number(updatedData.y),
        h: updatedData.h || 2,
        w: updatedData.w || 2,
        instrument_type: 'table',
        name: updatedData.name,
        dashboard_id: updatedData.dashboard_id,
      };
      const formattedTableData = formatIndicatorTableData({
        tableFilter,
        indicatorTableData: updatedData,
        expandKey: value.Time !== expandkey ? value.Time : '',
      });

      dispatch(handleAddAllChartData({ ...formattedTableData, ...layoutData }));
    } else if (value.isShowExpandable && timeAggregation !== 'Y') {
      if (expandkey !== value.Time && (tableFilter === 'Y' || tableFilter === 'Q')) {
        dispatch(setDashboardTableExpandKey(value.Time));
      } else {
        dispatch(setDashboardTableExpandKey(''));
      }
    }
  };

  const handleTitleChange = (title: string) => {
    if (chartData?.dashboard_id && chartData?.id) {
      const payload = {
        id: chartData?.id,
        dashboard_id: chartData?.dashboard_id,
        name: title || chartData?.name,
        instrument_type: 'table',
      };
      dispatch(updateInstrumentType({ ...payload }));
    }
  };

  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    if (parentReference?.current?.clientHeight) {
      setHeight(parentReference?.current?.clientHeight);
    }
  }, [parentReference?.current?.clientHeight]);

  const scrollReference = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (scrollReference.current && actualTableHeaders) {
      const scrollContainer = scrollReference.current;
      const targetIndex = actualTableHeaders.findIndex((header: any) => !header.isActualValue) - 1;
      if (targetIndex !== -1) {
        const targetElement = scrollContainer.querySelector(
          `th[data-index="${targetIndex}"]`,
        ) as HTMLElement;
        if (targetElement) {
          const { offsetLeft, offsetTop } = targetElement;
          scrollContainer.scrollTo({
            top: offsetTop - 250,
            left: offsetLeft - 250,
            behavior: 'smooth',
          });
        }
      }
    }
  }, [actualTableHeaders]);

  return (
    <Flex
      borderRadius='15px'
      // border={!isDashboardLayout ? '1px solid #787878' : ''}
      // background={'linear-gradient(180deg, #FFF 0%, #F5F5F5 100%)'}
      // boxShadow={'4px 4px 4px 4px rgba(0.25, 0.25, 0.25, 0.25)'}
      flexDirection={'column'}
      gap={1}
      height={'100%'}
      ref={parentReference}
    >
      <Box mx={3} mb={1} marginTop={isPreview ? 1 : '-25px'} maxWidth={'calc(100% - 55px)'}>
        <EditableTitle
          value={chartData?.name || allChartData[chartId]?.name}
          handleChange={handleTitleChange}
          isEditable={!isPreview}
        />
      </Box>

      <Box px={4}>
        <Flex flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Flex gap={2} alignItems={'center'}>
            {!isDashboardLayout && false && (
              <>
                <TimeRangeFilter chartId={chartId} chartFilters={chartData?.instrument_filters} />
                {/* <DimensionItemFilter /> */}
              </>
            )}
          </Flex>
          <Flex justifyContent={'flex-end'}>
            <TimeAggregationFilter isDashboardLayout={isDashboardLayout} chartId={chartId} />
          </Flex>
        </Flex>
        {actualTableHeaders && height && (
          <Box
            borderRadius='10px'
            overflowY='auto'
            overflowX='auto'
            h={`${height ? height - 50 : 0}px`}
            className='scroll'
          >
            <TableContainer
              ref={scrollReference}
              overflowY='auto'
              overflowX='auto'
              mb={{ base: 4.75, md: 0 }}
              mt={{ base: 0, md: 0 }}
              borderRadius='10px'
              className='table_scroll indicator-table dashboard-drag-header'
              cursor='grab'
            >
              <Table variant='base'>
                <Thead height={'9'} zIndex={2}>
                  <Tr>
                    <Th
                      textAlign={'left'}
                      fontFamily={'regular_roboto'}
                      fontSize={'xs'}
                      fontWeight={500}
                      textTransform='none'
                    >
                      Indicators
                    </Th>
                    {/* background={dataHadActualValue ? '#4AB7BF !important' : ''}  */}
                    {actualTableHeaders?.map(
                      (date: any, index: number) =>
                        date.isShow && (
                          <Th
                            data-index={index}
                            background={date?.isActualValue ? '#4AB7BF' : ''}
                            fontFamily={'regular_roboto'}
                            fontSize={'xs'}
                            fontWeight={500}
                            textTransform='none'
                            style={{
                              cursor: date.isShowExpandable ? 'pointer' : 'default',
                              paddingRight: '1rem',
                            }}
                            onClick={() => {
                              expand(date);
                            }}
                            key={index}
                            justifyContent='space-around'
                            textAlign={'start'}
                          >
                            {date.isShowExpandable}
                            {date.isShowExpandable &&
                              (expandkey === date.Time ? <ArrowRightTable /> : <ArrowLeftTable />)}
                            {date.Time}
                          </Th>
                        ),
                    )}
                  </Tr>
                </Thead>

                {actualTableData && (
                  <Tbody>
                    {actualTableData?.map((row: any, index: number) => (
                      <React.Fragment key={index}>
                        <DashboardTableRow
                          isDashboardLayout={isDashboardLayout}
                          key={row.id}
                          row={row}
                          expandkey={expandkey}
                        />
                      </React.Fragment>
                    ))}
                  </Tbody>
                )}
              </Table>
            </TableContainer>
          </Box>
        )}
      </Box>
    </Flex>
  );
};

export default DashboardTable;
