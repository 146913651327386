/* eslint-disable consistent-return */
import {
  Flex,
  Text,
  Menu,
  Input,
  Spinner,
  MenuList,
  MenuItem,
  MenuButton,
  useDisclosure,
  InputLeftElement,
  InputGroup,
} from '@chakra-ui/react';
import { GoChevronDown } from 'react-icons/go';
import { useRef, useEffect, useMemo, useState } from 'react';
import { FixedSizeList as List } from 'react-window';
import { useSelector, useDispatch } from 'react-redux';

import { STATUS } from 'projectConstants';
import {
  getBlock,
  getIndicators,
  handleWidgetsIndicator,
  getFetchIndicatorStatus,
  getDashboardChartId,
  fetchDashboardChart,
  fetchDashboardGraph,
  updateChartMetric,
  createChartInstrument,
  getChartType,
  getDashboardWidgetType,
  getModelDashboardId,
} from 'redux/DashboardSlice';
import { AppDispatch } from 'utils/GlobalHelpers';
import SearchIcon from 'components/Icons/SearchIcon';
import { getTimescaleDate } from 'redux/ModelsSlice';
import { formateDate, convertDate } from 'utils/ConvertDate';
import { getAllScenarios } from 'redux/ScenarioSlice';

const DashboardModalIndicator = ({ chartMatrix, isEditKpi }: any) => {
  console.log(chartMatrix);
  const dispatch: AppDispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const getTimeDate = useSelector(getTimescaleDate);
  const chartId = useSelector(getDashboardChartId);
  const blockDetails = useSelector(getBlock);
  const indicatorDetails = useSelector(getIndicators);
  const indicatorStatus = useSelector(getFetchIndicatorStatus);
  const [listData, setListData] = useState(indicatorDetails);
  const [searchValue, setSearchValue] = useState('');
  const [selectedIndicator, setSelectedIndicator] = useState<any>(null);
  const widgetType = useSelector(getDashboardWidgetType);
  const chartType = useSelector(getChartType);
  const allScenarios = useSelector(getAllScenarios);
  const modelDashboardId = useSelector(getModelDashboardId);

  const scenarioId = allScenarios.find((item: any) => item.is_base)?.id;

  const formattedStartDate = convertDate(formateDate(getTimeDate?.startDate));
  const formattedEndDate = convertDate(formateDate(getTimeDate?.endDate));

  const inputReference: any = useRef(null);

  const itemHeight = 30;
  const calculatedHeight = listData.length * itemHeight;
  const height = Math.min(calculatedHeight, 150);
  const width = 260;

  const matricId = useMemo(() => {
    let matric;
    if (chartMatrix) {
      matric = chartMatrix?.map((item: any) => item.id)[0];
    }
    if (isEditKpi) {
      const indicatorData = chartMatrix?.map((item: any) => item.indicator_name)[0];
      const indicatorFilter = indicatorDetails.filter(
        (item: any) => item.indicator_name === indicatorData,
      );
      dispatch(handleWidgetsIndicator(indicatorFilter));
      setSelectedIndicator(indicatorData);
    }
    return matric;
  }, [chartMatrix]);

  const kpiPayload = (indicator: any) => {
    const payload: any = {
      name: widgetType === 1 ? 'KPI' : widgetType === 2 ? 'chart' : 'kpi',
      dashboard_id: modelDashboardId,
      instrument_type: widgetType === 1 ? 'kpi' : chartType === 1 ? 'pie' : 'kpi',
      formatting: {
        units: 'auto',
        sparkline: false,
        decimalPlaces: 0,
        negativeDisplay: 'Minus Sign',
      },
      position: 0,
      width: chartType === 1 ? 4 : 4,
      height: chartType === 1 ? 3 : 2,
      instrument_indicators: [
        {
          aggregate_function: 'sum',
          formatting: {},
          indicator_name: indicator.indicator_name,
          indicator_id: indicator.indicator_id,
          block_id: blockDetails?.blockId,
        },
      ],
      instrument_filters: [
        {
          name: 'Time',
          operator: 'between',
          value: formattedStartDate,
          second_value: formattedEndDate,
          filter_type: 'primary',
        },
      ],
      instrument_sorts: [],
      instrument_dimensions: [],
      conditional_formatting: [],
    };
    return payload;
  };

  const handleIndicatorChange = (data: any) => {
    setSelectedIndicator(data.indicator_name);
    const chartPayload = kpiPayload(data);
    if (chartType === 1) {
      chartPayload.formatting.displayValue = false;
      chartPayload.formatting.donutHoleSize = 0;
    }
    if (chartType === 2) {
      chartPayload.formatting.displayMarkers = false;
      chartPayload.formatting.showActualPeriod = false;
      chartPayload.formatting.showLabels = false;
      chartPayload.formatting.leftAxis = {
        minValue: 0,
        maxValue: 0,
      };
    }
    if (chartType === 3) {
      chartPayload.formatting.stuckBars = false;
      chartPayload.formatting.showActualPeriod = false;
      chartPayload.formatting.showLabels = false;
      chartPayload.formatting.leftAxis = {
        minValue: 0,
        maxValue: 0,
      };
    }
    dispatch(handleWidgetsIndicator(data));
    if (chartId) {
      const payload = {
        id: matricId,
        instrument_id: chartId,
        indicator_id: data?.indicator_id,
        indicator_name: data?.indicator_name,
        aggregate_function: 'sum',
        formatting: {},
        block_id: blockDetails?.blockId,
        dimension_name: null,
      };
      dispatch(updateChartMetric(payload)).then(() => {
        dispatch(fetchDashboardChart(chartId));
        if (widgetType === 2) {
          dispatch(
            fetchDashboardGraph({
              chartId,
              scenarioId,
              requestBody: [],
            }),
          );
        }
      });
    } else {
      dispatch(createChartInstrument(chartPayload)).then((responseData: any) => {
        dispatch(fetchDashboardChart(responseData?.payload?.id));
        if (widgetType === 2) {
          dispatch(
            fetchDashboardGraph({
              chartId: responseData?.payload?.id,
              scenarioId,
              requestBody: [],
            }),
          );
        }
      });
    }
  };

  useMemo(() => {
    if (indicatorDetails) {
      setListData(indicatorDetails);
    }
  }, [indicatorDetails]);

  const Row = ({ index, style, data }: any) => {
    const handleItemClick = () => {
      handleIndicatorChange(data[index]);
      onClose();
    };

    return (
      <Flex
        alignItems={'center'}
        borderRadius={'6px'}
        paddingInline={2}
        _hover={{ backgroundColor: '#EFF2F6' }}
        onClick={handleItemClick}
        style={style}
        sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
        cursor={'pointer'}
      >
        {data[index].indicator_name}
      </Flex>
    );
  };

  useEffect(() => {
    const getData = setTimeout(() => {
      setListData(
        indicatorDetails?.filter((item: any) => item.indicator_name.includes(searchValue)),
      );
    }, 500);
    return () => clearTimeout(getData);
  }, [searchValue]);

  return (
    <Flex flexDir={'column'}>
      <Text color={'black.900'} fontWeight={'600'} mb={2}>
        Indicator
      </Text>
      <Menu isOpen={isOpen} onClose={onClose} strategy='fixed' isLazy variant={'primary'}>
        <MenuButton width={'100%'} onClick={isOpen ? onClose : onOpen}>
          <Flex
            justifyContent={'space-between'}
            alignItems={'center'}
            backgroundColor={'#FAFAFA'}
            border={'1px #D9D9D9 solid'}
            padding={'.5rem .8rem'}
            borderRadius={'6px'}
            color={'black.600'}
            fontSize={'2xs'}
          >
            <Text>{selectedIndicator || 'Click to select indicator'}</Text>
            <GoChevronDown size={18} />
          </Flex>
        </MenuButton>
        <MenuList p={'1.2rem'} border={'5px solid #D9D9D9'} borderRadius={'14.21px'}>
          {blockDetails ? (
            <>
              {indicatorStatus === STATUS.LOADING ? (
                <MenuItem>
                  <Spinner size={'md'} />
                </MenuItem>
              ) : (
                <>
                  <Flex alignItems={'center'} height={'32px'} marginBottom={'1.3rem'}>
                    <InputGroup>
                      <InputLeftElement
                        pointerEvents='none'
                        h='100%'
                        children={<SearchIcon width={'28.65px'} height={'22.93px'} />}
                      />
                      <Input
                        width={'93%'}
                        color={'black.900'}
                        _placeholder={{ color: '#D9D9D9' }}
                        ref={inputReference}
                        placeholder='Search'
                        onChange={(event: any) => {
                          setSearchValue(event.target.value);
                        }}
                      />
                    </InputGroup>
                  </Flex>
                  {indicatorDetails?.length > 0 ? (
                    <List
                      className='scroll'
                      height={height}
                      itemData={listData}
                      itemCount={listData?.length}
                      itemSize={itemHeight}
                      width={width}
                    >
                      {Row}
                    </List>
                  ) : (
                    <Text>No Indicator found</Text>
                  )}
                </>
              )}
            </>
          ) : (
            <Text>Select Block</Text>
          )}
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default DashboardModalIndicator;
