import * as React from 'react';
import { Flex, Stack, Box, Text, Input, Checkbox, Tooltip } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import DimensionTabIcon from 'components/Icons/ModelOverviewTabs/DimensionTabIcon';
import { AppDispatch } from 'utils/GlobalHelpers';
import { handleSelectAllBlockDimension, handleSelectedBlockDimension } from 'redux/PlanPageSlice';

const MultiSelectCheckBox = ({ clearButton, checkboxData, isMenuOpen }: any) => {
  const dispatch: AppDispatch = useDispatch();

  const [searchValue, setSearchValue]: any = useState('');

  const [ischeckedItemsChecked, setCheckedItemsChecked] = useState(
    checkboxData?.items?.map((item: any) => {
      return { name: item, status: true, search: true };
    }),
  );

  useEffect(() => {
    setCheckedItemsChecked(
      checkboxData?.items?.map((item: any) => {
        return { name: item, status: true, search: true };
      }),
    );
  }, [checkboxData]);

  useEffect(() => {
    if (!isMenuOpen && searchValue) {
      setSearchValue('');
    }
  }, [isMenuOpen]);

  const allChecked = ischeckedItemsChecked?.every((item: any) => item.status);
  const isIndeterminate = ischeckedItemsChecked?.some((item: any) => item.status) && !allChecked;

  useEffect(() => {
    if (clearButton) {
      setCheckedItemsChecked(
        checkboxData?.items?.map((item: any) => {
          return { name: item, status: true, search: true };
        }),
      );
    }
  }, [clearButton]);

  useEffect(() => {
    if (searchValue) {
      const searchTerm = searchValue.toLowerCase();
      const updatedItems = ischeckedItemsChecked?.map((item: any) => ({
        ...item,
        search: item.name.toLowerCase().includes(searchTerm),
      }));
      setCheckedItemsChecked(updatedItems);
    } else {
      const updatedItems = ischeckedItemsChecked?.map((item: any) => ({
        ...item,
        search: true,
      }));
      setCheckedItemsChecked(updatedItems);
    }
  }, [searchValue]);

  const handleCheckBoxChange = (user: any, event: any) => {
    dispatch(
      handleSelectedBlockDimension({
        dimensionName: checkboxData?.name,
        type: event.target.checked,
        itemName: user,
      }),
    );
    const find = ischeckedItemsChecked.findIndex((item: any) => item.name === user);
    setCheckedItemsChecked((previousState: any) => {
      const newState = [...previousState];
      newState[find].status = event.target.checked;
      return newState;
    });
  };

  const handleAllCheckBoxChange = (event: any) => {
    dispatch(
      handleSelectAllBlockDimension({
        dimensionName: checkboxData?.name,
        type: event.target.checked,
        ischeckedItemsChecked,
      }),
    );
    const allDimension = ischeckedItemsChecked?.map((item: any) => {
      return {
        name: item?.name,
        status: event.target.checked,
        search: true,
      };
    });
    setCheckedItemsChecked(allDimension);
  };

  return (
    <Stack minW={'200px'} maxW={'200px'} position={'relative'}>
      <Box position={'sticky'} top={'0'} backgroundColor={'white'} zIndex={1}>
        <Flex>
          <DimensionTabIcon width={7} height={6} color={'#4BB6B9'} />
          <Text variant={'primary_sub_heading'}>{checkboxData?.name}</Text>
        </Flex>
        {ischeckedItemsChecked?.length > 0 && (
          <React.Fragment>
            <Input
              my={3}
              placeholder='Search'
              height={7}
              fontSize={'14px'}
              value={searchValue}
              onChange={(event: any) => setSearchValue(event.target.value)}
            />
            {!searchValue && (
              <Checkbox
                variant={'filters'}
                className='filters-all-checkbox'
                isChecked={allChecked}
                isIndeterminate={isIndeterminate}
                onChange={handleAllCheckBoxChange}
              >
                Select all
              </Checkbox>
            )}
          </React.Fragment>
        )}
      </Box>
      {ischeckedItemsChecked?.length > 0 ? (
        <Stack maxH={'150px'} overflowX={'auto'} className={'filters_scroll'}>
          {ischeckedItemsChecked?.map((user: any, index: number) => (
            <React.Fragment key={index}>
              {user?.search && (
                <Checkbox
                  pl={6}
                  variant={'filters'}
                  isChecked={ischeckedItemsChecked[index]?.status}
                  onChange={(event) => {
                    handleCheckBoxChange(user?.name, event);
                  }}
                >
                  <Tooltip label={user?.name}>
                    <Text maxW={'100px'} className='text-overflow' size={'Roboto-14'}>
                      {user?.name}
                    </Text>
                  </Tooltip>
                </Checkbox>
              )}
            </React.Fragment>
          ))}
        </Stack>
      ) : (
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignContent={'center'}
        >
          <Text>No Dimension items</Text>
        </Box>
      )}
    </Stack>
  );
};

export default MultiSelectCheckBox;
