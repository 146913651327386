/* eslint-enable unicorn/prefer-switch */
/* eslint-disable unicorn/prefer-switch */

import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Flex, Text, Modal, ModalBody, ModalOverlay, ModalContent, Box } from '@chakra-ui/react';

import VisibleIconComponent from 'components/Icons/VisibleIcon';
import {
  getWidgetData,
  handleWidgetType,
  resetDashboardModal,
  getDashboardWidgetType,
  fetchDashboardGraph,
  fetchDashboardChart,
  getDashboardChartId,
  addAllDashboardTableData,
  handleAddAllChartData,
  getTableIndicator,
  getAllChartData,
  getKpiChartData,
  getDashboardSelectedScenario,
  handleDeleteDashboardChart,
  deleteChart,
  getIsEditModal,
} from 'redux/DashboardSlice';
import { AppDispatch } from 'utils/GlobalHelpers';
import { DASHBOARD_INSTRUMENT_TYPE } from 'projectConstants';
import formatIndicatorTableData from 'utils/FormatTable';

import KPIPreview from '../kpi/preview';
import ChartPreview from '../chart/preview';
import TablePreview from '../table/preview';
import TextBoxPreview from '../textbox/preview';

import DashboardModalTabs from './Tabs';
import DashboardModalHeading from './Heading';
import DashboardModalButtons from './Buttons';

const DashboardModal = () => {
  const dispatch: AppDispatch = useDispatch();
  const isEdit = useSelector(getIsEditModal);
  const chartId = useSelector(getDashboardChartId);
  const allChartData: any = useSelector(getAllChartData);
  // const screenHeight = window.screen.height;
  const widgetData = useSelector(getWidgetData);
  const dashboardWidgetType = useSelector(getDashboardWidgetType);
  const pieGraphData = useSelector(getTableIndicator);

  const AllPreviewData = useSelector(getKpiChartData);

  const selectedScenario = useSelector(getDashboardSelectedScenario);
  const scenarioId = selectedScenario?.id;

  const formatPieGraphData = useMemo(() => {
    const pieData = {
      ...pieGraphData,
      h: 3,
      w: 3,
      x: 0,
      y: 4,
      id: chartId,
    };
    return pieData;
  }, [pieGraphData, chartId]);

  // @typescript-eslint/no-unused-vars
  const fetchUpdatedInstrumentData = () => {
    if (chartId) {
      const currentChartData = allChartData[chartId];
      const layoutData = {
        id: currentChartData?.id || chartId,
        x: Number(currentChartData?.x) || (Object.keys(allChartData).length % 2) * 4,
        y: Number(currentChartData?.y) || Math.floor(Object.keys(allChartData).length / 2),
        h: currentChartData?.h || 3,
        w: currentChartData?.w || 4,
        name: currentChartData?.name,
        dashboard_id: currentChartData?.dashboard_id,
      };

      if (dashboardWidgetType === DASHBOARD_INSTRUMENT_TYPE.TABLE) {
        dispatch(fetchDashboardGraph({ chartId, scenarioId, requestBody: [] })).then(
          (data: any) => {
            if (!data.error) {
              dispatch(
                addAllDashboardTableData({
                  ...data.payload,
                  ...layoutData,
                  instrument_type: 'table',
                }),
              );
              const formattedTableData = formatIndicatorTableData({
                indicatorTableData: data?.payload,
                expandKey: '',
                tableFilter: 'M',
              });

              dispatch(
                handleAddAllChartData({
                  ...formattedTableData,
                  ...layoutData,
                  instrument_type: 'table',
                }),
              );
            }
          },
        );
      } else if (dashboardWidgetType === DASHBOARD_INSTRUMENT_TYPE.KPI) {
        dispatch(fetchDashboardChart(chartId)).then((data: any) => {
          dispatch(handleAddAllChartData({ ...data.payload, ...layoutData }));
        });
      } else if (dashboardWidgetType === DASHBOARD_INSTRUMENT_TYPE.CHART) {
        dispatch(fetchDashboardGraph({ chartId, scenarioId, requestBody: [] })).then(
          (data: any) => {
            if (!data.error) {
              dispatch(
                handleAddAllChartData({
                  ...data.payload,
                  ...layoutData,
                  instrument_type: data.payload?.instrument_type,
                }),
              );
            }
          },
        );
      }
    }
  };
  const handleCreate = () => {
    fetchUpdatedInstrumentData();
    dispatch(handleWidgetType(0));
  };
  const handleCancel = () => {
    dispatch(deleteChart({ chartId })).then(() => {
      dispatch(handleDeleteDashboardChart(chartId));
    });
    dispatch(handleWidgetType(0));
  };
  useEffect(() => {
    return () => {
      dispatch(resetDashboardModal());
    };
  }, []);

  return (
    <Modal
      isOpen
      isCentered
      onClose={() => {
        dispatch(handleWidgetType(0));
        if (!isEdit) {
          handleCancel();
        }
      }}
      motionPreset='slideInBottom'
      blockScrollOnMount={false}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent
        height={'80%'}
        maxH={'80%'}
        maxWidth={'80%'}
        overflow={'hidden'}
        color={'black.600'}
      >
        <ModalBody padding={0} height={'inherit'}>
          <Flex height={'100%'}>
            <Flex
              width={'70%'}
              bg={'linear-gradient(to right, #f5f5f5, #d9d9d9)'}
              p={'1rem 1.5rem'}
              borderLeftRadius={'md'}
              flexDir={'column'}
            >
              <Flex alignItems={'center'} mb={5}>
                <VisibleIconComponent />
                <Text size={'Comfortaa-bold-20'} ml={3}>
                  Preview
                </Text>
              </Flex>
              {(widgetData?.indicatorData && widgetData.tableIndicator.length > 0) ||
              AllPreviewData ? (
                <Flex width={'100%'} height={'100%'} justifyContent={'center'}>
                  <Flex
                    gap={2}
                    width={'80%'}
                    flexDir={'column'}
                    bgColor={'#FEFEFE'}
                    borderRadius={'12px'}
                    boxShadow={'0px 4px 4px rgba(0, 0, 0, 0.25)'}
                    height={'95%'}
                  >
                    {dashboardWidgetType === 1 ? (
                      <KPIPreview kpiPreviewData={AllPreviewData !== null && AllPreviewData} />
                    ) : dashboardWidgetType === 2 ? (
                      <ChartPreview
                        chartPreviewData={formatPieGraphData !== null && formatPieGraphData}
                      />
                    ) : dashboardWidgetType === 3 ? (
                      <TablePreview />
                    ) : dashboardWidgetType === 4 ? (
                      <TextBoxPreview />
                    ) : null}
                  </Flex>
                </Flex>
              ) : (
                <Flex
                  width={'100%'}
                  height={'35%'}
                  sx={{
                    border: '1px solid transparent',
                    borderImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='17' ry='17' stroke='%23333' stroke-width='2' stroke-dasharray='10' stroke-dashoffset='25' stroke-linecap='square'/%3e%3c/svg%3e");`,
                    borderRadius: '10px',
                    borderImageSlice: 1,
                  }}
                  bg={'white'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Text fontFamily={'Roboto'} size={'Comfortaa-bold-20'} ml={3}>
                    Select an indicator from the setup to show a preview
                  </Text>
                </Flex>
              )}
            </Flex>
            <Flex
              height={'100%'}
              width={'30%'}
              pt={'1rem'}
              overflow={'hidden'}
              justifyContent={'space-between'}
              flexDir={'column'}
            >
              <DashboardModalHeading />
              <Box height={'80%'} maxH={'80% !important'} className='scroll' overflow='scroll'>
                <DashboardModalTabs />
              </Box>
              <Flex justifyContent={'flex-end'} alignItems={'end'} bg='white'>
                <DashboardModalButtons handleCreate={handleCreate} handleCancel={handleCancel} />
              </Flex>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DashboardModal;
