import { Text, MenuButton, Menu, MenuItem, MenuList, Flex, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { GoChevronDown } from 'react-icons/go';
import { useDispatch, useSelector } from 'react-redux';

import BarChartIcon from 'components/Icons/BarChartIcon';
import {
  getChartType,
  handleChartType,
  getKpiChartData,
  updateInstrumentType,
} from 'redux/DashboardSlice';
import LineChartIconComponent from 'components/Icons/LineChartIconComponent';
import { AppDispatch } from 'utils/GlobalHelpers';

const ChartType = () => {
  const dispatch: AppDispatch = useDispatch();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const kpiData = useSelector(getKpiChartData);
  const chartType = useSelector(getChartType);

  const handleChartSwitch = (type: number) => {
    if (kpiData?.dashboard?.id && kpiData?.id) {
      const payload = {
        id: kpiData?.id,
        dashboard_id: kpiData?.dashboard?.id,
        name: kpiData?.name,
        instrument_type: type === 2 ? 'line' : 'bar',
      };
      dispatch(updateInstrumentType({ ...payload }));
    }
    dispatch(handleChartType(type));
    onClose();
  };

  const getChartNameIcon = () => {
    switch (chartType) {
      // case 1:
      //   return (
      //     <>
      //       <PieChartIcon marginRight='.5rem' />
      //       <Text>{'Pie Chart'}</Text>
      //     </>
      //   );
      case 2:
        return (
          <>
            <LineChartIconComponent marginRight='.5rem' />
            <Text>{'Line Chart'}</Text>
          </>
        );
      case 3:
        return (
          <>
            <BarChartIcon marginRight='.5rem' />
            <Text>{'Column Chart'}</Text>
          </>
        );
      // case 4:
      //   return (
      //     <>
      //       <ComboChartIcon marginRight='.5rem' />
      //       <Text>{'Combo Chart'}</Text>
      //     </>
      //   );
      default:
        return (
          <>
            <LineChartIconComponent marginRight='.5rem' />
            <Text>{'Click to select Chart'}</Text>
          </>
        );
    }
  };

  return (
    <Flex flexDir={'column'}>
      <Text color={'black.900'} fontWeight={'600'} mb={2}>
        Chart Type
      </Text>
      <Menu isOpen={isOpen} onClose={onClose} strategy='fixed' isLazy variant={'primary'}>
        <MenuButton width={'100%'} onClick={isOpen ? onClose : onOpen}>
          <Flex
            justifyContent={'space-between'}
            backgroundColor={'#FAFAFA'}
            border={'1px #D9D9D9 solid'}
            padding={'.5rem .8rem'}
            borderRadius={'6px'}
            color={'black.600'}
            fontSize={'2xs'}
          >
            <Flex alignItems={'center'}>{getChartNameIcon()}</Flex>
            <GoChevronDown size={18} />
          </Flex>
        </MenuButton>
        <MenuList p={'1rem'}>
          <MenuItem onClick={() => handleChartSwitch(2)}>
            <LineChartIconComponent marginRight='.5rem' />
            Line Chart
          </MenuItem>
          <MenuItem onClick={() => handleChartSwitch(3)}>
            {' '}
            <BarChartIcon marginRight='.5rem' /> Column Chart
          </MenuItem>
          {/* <MenuItem onClick={() => handleChartSwitch(1)}>
            <PieChartIcon marginRight='.5rem' />
            Pie Chart
          </MenuItem>
          <MenuItem onClick={() => handleChartSwitch(4)}>
            <ComboChartIcon marginRight='.5rem' /> Combi Chart
          </MenuItem> */}
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default ChartType;
