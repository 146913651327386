/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Text, Box, Flex, Tooltip } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { AppDispatch } from 'utils/GlobalHelpers';
import TrendingDown from 'components/Icons/TrendingDownIcon';
import {
  fetchDashboardGraph,
  getDashboardSelectedScenario,
  getModalData,
  updateInstrumentType,
} from 'redux/DashboardSlice';
import { getDate, getPreviousMonth, totalMonthsGap } from 'utils/ConvertDate';
import { getTimescaleDate } from 'redux/ModelsSlice';
import { getAllScenarios } from 'redux/ScenarioSlice';
import PriviewFilterByComponent from 'pages/PlanPageOutputOptimization/Plan/Filters/PreviewFilterBy';
import Spinner from 'components/Spinner';
import formatValue from 'utils/FormatValues';

import TimeRangeFilter from '../TimeRangeFilter';
import EditableTitle from '../EditableTitle';

import KPIChart from './KpiChart';

interface Properties {
  chartData: any;
  isDashboardLayout?: boolean;
  isPreview?: boolean;
}

const UNIT_MULTIPLIERS: any = {
  k: 1000,
  mn: 1_000_000,
  bn: 1_000_000_000,
  auto: 1,
};

const KPICard = ({ chartData, isDashboardLayout, isPreview }: Properties) => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch: AppDispatch = useDispatch();
  const getTimeDate = useSelector(getTimescaleDate);
  const selectedScenario = useSelector(getDashboardSelectedScenario);
  const scenarioId = selectedScenario?.id;
  const [totalValues, setTotalValues] = useState<number | null>(null);
  const [valueColor, setValueColor] = useState<any>('#57519C');
  const [kpiData, setKpiData] = useState<any[]>([]);
  const [isComparision, setIsComparision] = useState(false);
  const [kpiLabel, setKpiLabel] = useState<any[]>([]);
  const [comparisionText, setComparisionText] = useState<number | string>(0);

  const modalBlocks = useSelector(getModalData);

  const dataType = useMemo(() => {
    try {
      const foundBlock: any = modalBlocks.find((block: any) => {
        return block.block_id === chartData.instrument_indicators[0].block_id;
      });

      if (foundBlock?.indicators) {
        const indicator = foundBlock?.indicators.find((ind: any) => {
          return chartData?.instrument_indicators[0]?.indicator_id === ind.indicator_id;
        });
        return indicator.data_format_properties;
      }
    } catch (error: any) {
      console.error(error.message);
      return null;
    }
    return null;
  }, [chartData?.instrument_indicators?.length, modalBlocks]);

  console.log('dataType', dataType);

  function getColorBasedOnCondition(array: any, valueToCheck: any) {
    let color = null;
    const displayValue = Number.parseInt(valueToCheck, 10);
    const applicableConditions = array.filter((condition: any) => {
      const conditionValue = Number.parseInt(condition.formatting.value, 10);
      switch (condition.value.toLowerCase()) {
        case 'is less than':
          return displayValue < conditionValue;
        case 'is greater than':
          return displayValue > conditionValue;
        case 'equal to':
          return displayValue === conditionValue;
        default:
          return false;
      }
    });
    if (applicableConditions.length > 0) {
      applicableConditions.sort((a: any, b: any) => a.priority - b.priority);
      color = applicableConditions[0].formatting.color;
    }
    return color;
  }

  const handleTitleChange = (title: string) => {
    if (chartData?.dashboard?.id && chartData?.id) {
      const payload = {
        id: chartData?.id,
        dashboard_id: chartData?.dashboard?.id,
        name: title || chartData?.name,
        instrument_type: 'kpi',
      };

      dispatch(updateInstrumentType({ ...payload }));
    }
  };

  useEffect(() => {
    if (chartData) {
      dispatch(
        fetchDashboardGraph({
          chartId: chartData?.id,
          scenarioId,
          requestBody: [],
        }),
      ).then((response: any) => {
        setIsLoading(false);
        if (response.meta.requestStatus === 'fulfilled') {
          const totalValue = response.payload?.data?.map((item: any) => item[Object.keys(item)[0]]);
          const kpiGraphLabel = response.payload?.table?.map((item: any) => item.Time);
          const kpiGraphData = response.payload?.table?.map(
            (item: any) => item[Object.keys(item)[1]],
          );
          const comparisonValue = response.payload?.comaprison_value;

          if (chartData?.formatting?.negativeDisplay === 'brackets' && comparisonValue < 0) {
            setComparisionText(`(${Math.abs(comparisonValue)})`);
          } else {
            setComparisionText(comparisonValue);
          }
          const valueColor = getColorBasedOnCondition(chartData.conditional_formatting, totalValue);
          setValueColor(valueColor);
          setTotalValues(Math.abs(totalValue[0]));
          setKpiLabel(kpiGraphLabel);
          setKpiData(kpiGraphData);
        }
      });
    }
  }, [chartData]);

  const blockId = useMemo(() => {
    if (chartData?.instrument_indicators) {
      const firstMetric = chartData.instrument_indicators[0];
      return firstMetric ? firstMetric.block_id : null;
    }
    return null;
  }, [chartData?.instrument_indicators]);

  const isDimensionFilter = useMemo(() => {
    const filterData = chartData?.instrument_filters?.some(
      (item: any) => item?.filter_type === 'dimension',
    );
    return filterData || false;
  }, [chartData?.instrument_filters]);

  const comparisonType = useMemo(() => {
    let timeStartDate = '';
    let timeEndDate = '';
    let comparisonStartDate = '';
    let comparisonEndDate: any = '';
    const latestActuals = getPreviousMonth(new Date(getTimeDate?.startDate));
    chartData?.instrument_filters?.forEach((item: any) => {
      if (item?.name === 'Time' && item?.filter_type === 'primary') {
        timeStartDate = item?.value;
        timeEndDate = item?.second_value;
      }
      if (item.name === 'Time' && item.filter_type === 'comparision') {
        comparisonStartDate = item.value;
        comparisonEndDate = item?.second_value;
      }
    });
    setIsComparision(!!comparisonStartDate);
    const timeDate: any = getDate(timeStartDate);
    const comparisonDate: any = getDate(comparisonStartDate);
    const comparisonEndValue: any = getDate(comparisonEndDate);
    if (totalMonthsGap(timeDate, comparisonDate) === -12 && !comparisonEndValue) {
      return 'vs Single Month';
    }
    if (new Date(comparisonDate).getTime() === new Date(latestActuals).getTime()) {
      return 'vs Latest Actuals';
    }
    return comparisonEndValue ? 'vs Prior Year' : 'vs Single Month';
  }, [chartData?.instrument_filters, getTimeDate]);

  const calculateTotalValue = (value: number | null, divideValue: number) => {
    if (value !== null && value !== undefined) {
      return chartData?.formatting?.units === 'auto'
        ? `${(value / divideValue)
            .toFixed(chartData?.formatting?.decimalPlaces)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
        : `${(value / divideValue).toFixed(chartData?.formatting?.decimalPlaces)}${
            chartData?.formatting?.units
          }`;
    }
    return '0';
  };

  return (
    <Box
      height={'100%'}
      borderRadius='15px'
      border={isDashboardLayout ? '' : '1px solid #787878'}
      boxShadow={isDashboardLayout ? '' : '0px 4px 4px rgba(0, 0, 0, 0.25)'}
    >
      {isLoading && (
        <Flex
          height={'100%'}
          direction='column'
          alignItems='center'
          justifyContent='center'
          justify='center'
          align='center'
          w={'full'}
        >
          <Spinner styleProperties={{ my: '4vh' }} />
        </Flex>
      )}
      {!isLoading && (
        <>
          <Box mx={3} mb={1} marginTop={isPreview ? 1 : '-25px'} maxWidth={'calc(100% - 55px)'}>
            <EditableTitle
              value={chartData?.name}
              handleChange={handleTitleChange}
              isEditable={!isPreview}
            />
          </Box>
          <Flex
            flexDirection='column'
            justifyContent='space-between'
            alignItems='space-between'
            className='dashboard-drag-header'
            cursor='grab'
            // maxWidth={'calc(100% - 60px)'}
          >
            <Flex marginInline={4} mb={3} gap={3} alignItems='center'>
              {false && (
                <TimeRangeFilter
                  chartFilters={chartData?.instrument_filters}
                  chartId={chartData?.id}
                />
              )}
              {isDimensionFilter && (
                <PriviewFilterByComponent filterChartData={chartData} block_id={blockId} />
              )}
            </Flex>
            <Box marginY={3}>
              <Flex marginInline={4} justifyContent='space-between' alignItems='center' gap={5}>
                <Tooltip
                  label={
                    totalValues === null || totalValues === undefined || totalValues === 0
                      ? 0
                      : `${formatValue(totalValues, dataType)}`
                  }
                >
                  <Text
                    flex={1}
                    minWidth={0}
                    // w={chartData?.formatting?.sparkline ? '60%' : '100%'}
                    w={'100%'}
                    fontSize='6xl'
                    // fontFamily='regular_roboto'
                    fontWeight='400'
                    color={valueColor || '#57519C'}
                    className='text-overflow'
                  >
                    {totalValues === null || totalValues === undefined || totalValues === 0
                      ? 0
                      : `${formatValue(totalValues, dataType)}`}
                  </Text>
                </Tooltip>
                {chartData?.formatting?.sparkline && (
                  <>
                    <Box w='40%'>
                      <KPIChart kpiLabelData={kpiLabel} kpiChartData={kpiData} />
                    </Box>
                  </>
                )}
              </Flex>
            </Box>
            {isComparision && (
              <Flex m={2} mt={0} alignItems='center' h={5} gap={2}>
                <TrendingDown />
                <Text color='blackAlpha.900'>
                  <b>{`${comparisionText}% `}</b>
                  {`${comparisonType}`}
                </Text>
              </Flex>
            )}
          </Flex>
        </>
      )}
    </Box>
  );
};

export default KPICard;
