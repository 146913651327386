/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable unicorn/no-zero-fractions */
/* eslint-disable unicorn/numeric-separators-style */
import { Flex, useMediaQuery } from '@chakra-ui/react';
import { ReactGrid } from '@silevis/reactgrid';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { CHART_COLORS, HEADER_HEIGHT_WITH_NAVIGATION } from 'projectConstants';
import { fetchIndicatorDetail } from 'redux/IndicatorPageSlice';
import {
  FetchPlangPageIndicatorTableData,
  UpdatePlangPageIndicatorTableData,
  filterEndDate,
  filterStartDate,
  getBlockDetails,
  getIndicatorTableData,
  getModelDetails,
  getSelectedDriver,
  getmultiDimFiltersItems,
  planPageShow,
  updateIndicatorData,
} from 'redux/PlanPageSlice';
import { FetchBlockOutputs, getPlannerBlock, getPlannerModel } from 'redux/PlannerModeSlice';
import { getBaseScenario } from 'redux/ScenarioSlice';

import { HeaderRow } from './HeaderRow';
import { TextCellTemplate } from './TextCellTemplate';

import './Table.scss';

const headerRow = (tableProperty_: any) => ({
  rowId: 'header',
  height: 40,
  cells: [
    ...(tableProperty_?.length
      ? tableProperty_?.map((data: any, index: any) => {
          const row = {
            type: 'header',
            colType: 'header',
            nonEditable: true,
            property: data?.is_dimension ? 'dimension' : '',
            id: data?.id,
            text: data?.name,
            className: `header-cell ${index === tableProperty_?.length - 1 ? 'header-last' : ''} ${
              index === 0 ? 'header-first' : ''
            }`,
            data,
          };
          return row;
        })
      : []),
  ],
});

const getRows = (
  tableItemsProperty_: any,
  tableProperty_: any,
  isPercentage: any,
  modelCurrency?: string,
  date?: any,
) => {
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const isMonthlyNonEditable = (dataHeading: string) => {
    const headingYear = Number(dataHeading.split('-')[1]);
    const scenarioYear = Number(`${date}`.split('-')[1]);
    if (headingYear < scenarioYear) return true;
    if (
      headingYear === scenarioYear &&
      monthNames.indexOf(dataHeading.split('-')[0]) <= monthNames.indexOf(date.split('-')[0])
    )
      return true;
    return false;
  };
  const isCellNonEditable = (data: any) => {
    if (data?.is_dimension) return true;
    if (data.name[0] + data.name[1] === 'FY' && Number(data.name[2] + data.name[3]) + 2000 < date)
      return true;
    if (data.name[0] + data.name[1] !== 'FY') return isMonthlyNonEditable(data?.name);
    return false;
  };

  return [
    headerRow(tableProperty_),
    ...(tableItemsProperty_?.length
      ? tableItemsProperty_?.map((item: any, index: any) => {
          return {
            rowId: item?.id,
            height: 40,
            // itemName: item?.name,
            reorderable: true,
            // isNewCell: !!item?.isNewCell,
            cells: [
              ...(tableProperty_?.length
                ? tableProperty_?.map((data: any, childIndex: any) => {
                    const fData = data?.is_dimension
                      ? item[data.id]
                      : isPercentage === 'percentage'
                      ? item[data?.name] * 100
                      : item[data?.name];

                    const row = {
                      rowId: data?.is_dimension ? data?.id : data?.name,
                      type: 'text',
                      currencyType: modelCurrency,

                      // name: data?.type === 'dimension' ? data?.id : data?.name,
                      property: data?.is_dimension ? 'dimension' : '',
                      uniqueId: fData?.uniqueid,
                      format: isPercentage,
                      color: CHART_COLORS[fData?.uniqueid],
                      rows: tableItemsProperty_?.length,
                      itemmId: data?.is_dimension ? `${fData?.id}` : `${data?.id}`,
                      text: data?.is_dimension ? `${fData?.value}` : `${fData}` ?? '',
                      index,
                      data,
                      columnId: data?.name,
                      nonEditable: isCellNonEditable(data),
                      className: `${
                        tableItemsProperty_?.length - 1 === index && !data?.is_dimension
                          ? 'border-right'
                          : ''
                      } ${
                        tableItemsProperty_?.length - 1 === index && childIndex === 0
                          ? 'border-bottom'
                          : ''
                      }  ${!data?.is_dimension && isCellNonEditable(data) ? 'non-edit-bg' : ''}
                      
                      ${data?.is_dimension ? 'dimension-cell' : ''}`,
                    };
                    return row;
                  })
                : []),
            ],
          };
        })
      : []),
  ];
};

const calculateWidth = (totalElements: number, totalWidth: number) => {
  const nameField: number = 150;
  const editField: number = 60;
  const columnsWidth = totalElements * 150 + nameField + editField;
  return totalWidth > columnsWidth ? (totalWidth - editField) / (totalElements + 1) : 125;
};

const getColumns = (
  tableProperty_: any,
  tableReference: any,
  timeDim: any,
  currentType: string,
) => {
  const columnWidth =
    tableProperty_ || tableReference ? calculateWidth(tableProperty_?.length, tableReference) : 125;
  return [
    ...(tableProperty_ && tableProperty_?.length > 0
      ? tableProperty_?.map((data: any) => {
          const row = {
            columnId: data?.is_dimension
              ? `${data?.id}`
              : currentType === 'constant_by_year' || currentType === 'raw'
              ? `${timeDim?.id} ${data?.name}`
              : `${data?.name}`,
            name: data?.name,
            width: columnWidth,
            resizable: true,
            reorderable: !!data?.is_dimension,
            timeDimId: timeDim?.id,
          };
          return row;
        })
      : []),
  ];
};
const handleColumnResize = (ci: any, width: any, setColumns: any) => {
  setColumns((column: any) => {
    const columnIndex = column.findIndex((element: any) => element.columnId === ci);
    const resizedColumn = column[columnIndex];
    const updatedColumn = { ...resizedColumn, width };
    column[columnIndex] = updatedColumn;
    return [...column];
  });
};

interface Properties {
  refetchRequest: () => void;
  isPercentage: any;
  isBuilderMode?: boolean;
}

const MultiDimensionTable = ({ refetchRequest, isPercentage, isBuilderMode }: Properties) => {
  const tableReference: any = useRef(null);
  const { blockId } = useParams();
  const dispatch: any = useDispatch();
  const indicatorTableData = useSelector(getIndicatorTableData);
  // const selectedBlockDimension = useSelector(getSelectedBlockDimension);

  const baseScenario = useSelector(getBaseScenario);
  const dimensionList = useSelector(getBlockDetails);
  const currentDriver = useSelector(getSelectedDriver);
  const show = useSelector(planPageShow);
  const [render, setRender] = useState(false);
  const timeDimension = dimensionList?.dimensions.find(
    (item: any) => item?.name?.toLowerCase() === 'time',
  );
  const modelData = useSelector(getModelDetails);
  const plannerModelData = useSelector(getPlannerModel);
  const modelDetails = modelData || plannerModelData;
  const plannerBlock = useSelector(getPlannerBlock);
  const currentType = indicatorTableData?.type;
  const [isMobile] = useMediaQuery('(max-height: 780px)');
  const [isSmallDevice] = useMediaQuery('(max-height: 680px)');
  const [tableData, setTableData] = useState<any>('');
  const filteredItems = useSelector(getmultiDimFiltersItems);
  const startDateFilter = useSelector(filterStartDate);
  const endDateFilter = useSelector(filterEndDate);
  // const [globalRows, setTableDataRows] = useState<any>([]);
  const startDateObject = new Date(baseScenario?.start_date);
  startDateObject.setMonth(startDateObject.getMonth() - 1);
  // Format 1: Year 2023
  const yearFormat = startDateObject.getFullYear();

  // Format 3: Nov-23
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const monthFormat = `${monthNames[startDateObject.getMonth()]}-${String(yearFormat).slice(-2)}`;

  const targetDate = () => {
    if (indicatorTableData?.type === 'raw') {
      return monthFormat;
    }

    return yearFormat;
  };

  function extractYear(dateString: string) {
    const modelStartYear = moment(modelDetails?.time_properties?.starting_period).year() % 1000;

    if (modelDetails?.time_properties?.time_granularity.toLowerCase() === 'm') {
      const parsedDate = moment(dateString, 'MMM-YY');
      const extractedYear = parsedDate.year() % 1000;

      const fiscalMonthIndex = monthNames.indexOf(modelDetails?.time_properties?.fy_start_month);
      const modelMonthIndex = moment(modelDetails?.time_properties?.starting_period).month();
      if (extractedYear === modelStartYear && modelMonthIndex >= fiscalMonthIndex) {
        return `FY${extractedYear + 1}`;
      }
      return `FY${extractedYear}`;
    }
    return `FY${Number(dateString) % 1000}`;
  }

  const applyTimeFilter = (data?: any) => {
    if (currentType === 'constant_by_year' || currentType === 'raw') {
      const indicatorTableCopy = JSON.parse(JSON.stringify(data || indicatorTableData));
      const activeDim = indicatorTableCopy?.dimensions?.length;
      const startDate =
        currentType === 'constant_by_year' ? extractYear(startDateFilter) : startDateFilter;
      const endDate =
        currentType === 'constant_by_year' ? extractYear(endDateFilter) : endDateFilter;
      const columnStartIndex = indicatorTableCopy?.column_headers?.findIndex(
        (cell: any) => String(cell.name) === String(startDate),
      );
      const columnEndIndex = indicatorTableCopy?.column_headers?.findIndex(
        (cell: any) => String(cell.name) === String(endDate),
      );
      const excelStartIndex = Object.keys(indicatorTableCopy.excel_data[0]).findIndex(
        (cell: any) => String(cell) === String(startDate),
      );
      const excelEndIndex = Object.keys(indicatorTableCopy.excel_data[0]).findIndex(
        (cell: any) => String(cell) === String(endDate),
      );
      const columnDimData =
        activeDim > 0 ? indicatorTableCopy?.column_headers?.slice(0, activeDim) : [];
      indicatorTableCopy.column_headers = [
        ...columnDimData,
        ...indicatorTableCopy?.column_headers?.slice(columnStartIndex, columnEndIndex + 1),
      ];

      indicatorTableCopy.excel_data = indicatorTableCopy?.excel_data?.map((row: any) => {
        const dimData = activeDim > 0 ? Object.entries(row).slice(0, activeDim) : [];
        const slicedData = Object.fromEntries([
          ...dimData,
          ...Object.entries(row).slice(excelStartIndex, excelEndIndex + 1),
        ]);
        return slicedData;
      });
      return indicatorTableCopy;
    }
    return data;
  };
  const doesDimensionExist = () => {
    return indicatorTableData?.dimensions.find(
      (dim: any) => Number(filteredItems[0].dimId) === Number(dim.id),
    );
  };
  useEffect(() => {
    if (indicatorTableData && filteredItems && filteredItems.length > 0 && doesDimensionExist()) {
      const modifiedTable: any = { ...JSON.parse(JSON.stringify(indicatorTableData)) };
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      filteredItems &&
        filteredItems?.forEach((itemData: any) => {
          const dimensionId1 = `${itemData.dimId}`;
          const filterItems = itemData?.filterItems?.filter(
            (item: any) => item?.status && item?.search,
          );

          modifiedTable.excel_data = modifiedTable?.excel_data?.filter((object: any) => {
            if (object[dimensionId1.toString()]) {
              const { value } = object[dimensionId1.toString()];
              return filterItems?.some(
                (filterItem: any) =>
                  filterItem?.name?.trim()?.toLowerCase() === value?.trim()?.toLowerCase(),
              );
            }
            return false;
          });
        });
      const filteredTableData =
        startDateFilter && endDateFilter ? applyTimeFilter(modifiedTable) : modifiedTable;
      setTableData(filteredTableData);
    } else {
      const filteredTableData =
        startDateFilter && endDateFilter ? applyTimeFilter(indicatorTableData) : indicatorTableData;
      setTableData(filteredTableData);
    }
  }, [indicatorTableData, startDateFilter, endDateFilter, filteredItems]);

  useEffect(() => {
    setTimeout(() => {
      setRender(show);
    }, 500);
  }, [show]);

  const memorizedColumns: any = useMemo(() => {
    return (
      tableData &&
      tableData?.column_headers?.length >= 0 &&
      getColumns(
        tableData?.column_headers,
        tableReference.current?.offsetWidth,
        timeDimension,
        currentType,
      )
    );
  }, [tableData?.column_headers, tableReference, timeDimension]);
  const [columns, setColumns]: any = useState(memorizedColumns || []);

  const rows: any = useMemo(() => {
    const date = targetDate();
    if (tableData && tableData?.dimensions?.length > 0) {
      const uniqueIds: any = {};
      let counter = 0;
      const myFirstId: any =
        (tableData?.column_headers.length > 0 && tableData?.column_headers[0]?.id) || 0;
      const temporary = JSON.parse(JSON.stringify(tableData && tableData?.excel_data)) || [];
      const newArray =
        myFirstId &&
        tableData &&
        tableData?.column_headers.length > 0 &&
        tableData?.excel_data?.length > 0 &&
        temporary?.map((element: any, index: number) => {
          if (uniqueIds[element[myFirstId].id] === undefined) {
            uniqueIds[element[myFirstId].id] = counter;
            element[myFirstId].uniqueid = counter;
            element.id = index + 1;
            counter += 1;
          } else {
            element[myFirstId].uniqueid = uniqueIds[element[myFirstId].id];
            element.id = index + 1;
          }

          return element;
        });
      return getRows(
        newArray,
        tableData?.column_headers,
        isPercentage,
        modelDetails?.reporting_currency,
        date,
      );
    }
    return getRows(
      tableData?.excel_data,
      tableData?.column_headers,
      isPercentage,
      modelDetails?.reporting_currency,
      date,
    );
  }, [tableData, render, isPercentage, baseScenario?.start_date]);

  useEffect(() => {
    setColumns(memorizedColumns);
  }, [memorizedColumns, render]);

  const updateCellData = async (changes: any) => {
    const organizedData: any = {};

    changes?.forEach((change: any) => {
      const { rowId, columnId, newCell }: any = change;
      const value = isPercentage === 'percentage' ? Number(newCell.text) / 100 : newCell.text;

      // If the rowId is not yet present in the organized data, initialize it
      if (!organizedData[rowId]) {
        organizedData[rowId] = {};
      }

      // Assign the value to the respective columnId within the rowId
      organizedData[rowId][columnId] = value;
    });
    // Convert the organized data object into an array
    const result = Object.entries(organizedData).map(([rowId, rowData]: any) => {
      return { ...rowData, rowId: Number.parseInt(rowId, 10) };
    });

    let matchingData: any;
    rows.forEach((row: any) => {
      matchingData = result && result?.find((dataObject: any) => dataObject.rowId === row.rowId);
      if (matchingData) {
        row.cells.forEach((cell: any) => {
          if (cell.property === 'dimension') {
            matchingData[cell.rowId] = cell.text;
            delete matchingData.rowId;
          }
        });
      }
    });

    const fetchIndicatorsData = async () => {
      const idArray = plannerBlock?.dimensions
        .filter((item: any) => item.name !== 'Time')
        .map((item: any) => item.id);
      const dimensionIds = idArray.join(', ');
      const payload1 = {
        blockId,
        params: {
          dim_id: dimensionIds,
          indicator_filter: 'all',
          scenario_id: baseScenario?.id,
        },
      };
      dispatch(FetchBlockOutputs(payload1));
    };

    if (indicatorTableData?.type === 'constant_by_year' || indicatorTableData?.type === 'raw') {
      const dimId = timeDimension?.id;
      const response: any = [];

      result?.forEach((change) => {
        const dimKeys = Object.keys(change).filter((key) => key.startsWith(dimId.toString()));
        dimKeys.forEach((dimKey) => {
          const [, year] = dimKey.split(' ');
          const value = change[dimKey];
          const { [dimKey]: deletedKey, ...newChange } = change;
          const payload = {
            [dimId.toString()]: year,
            value,
            ...newChange,
          };
          const newPayload: any = {};
          Object.keys(payload).forEach((key) => {
            if (key.split(' ').length === 1) {
              newPayload[key] = payload[key];
              delete newPayload.rowId;
            }
          });
          response.push(newPayload);
        });
      });
      const payload = {
        indicator_id: indicatorTableData?.indicator_id,
        request_body: {
          bulk_input: true,
          format_excel_data: 'true',
          dimensions: indicatorTableData?.dimensions,
          data_values: response,
          type: indicatorTableData?.type,
          scenario_id: baseScenario?.id,
        },
      };

      await dispatch(UpdatePlangPageIndicatorTableData(payload)).then(() => {
        refetchRequest();
        if (plannerBlock?.dimensions.length > 0) {
          fetchIndicatorsData();
        }
      });
    } else {
      result?.forEach((change) => {
        delete change.rowId;
      });

      const payload = {
        indicator_id: indicatorTableData?.indicator_id,
        request_body: {
          bulk_input: true,
          format_excel_data: 'true',
          dimensions: indicatorTableData?.dimensions,
          data_values: result,
          type: indicatorTableData?.type,
          scenario_id: baseScenario?.id,
        },
      };

      await dispatch(UpdatePlangPageIndicatorTableData(payload)).then(() => {
        refetchRequest();
        if (plannerBlock?.dimensions.length > 0) {
          fetchIndicatorsData();
        }
      });
    }
  };
  const handleColumnsReorder = async (targetColumnId: any, columnIds: any) => {
    const idsArray: any = [];
    indicatorTableData?.column_headers.map((dim: any) => {
      if (dim.is_dimension) {
        idsArray.push(String(dim.id));
      }
      return null;
    });

    const targetIndex = idsArray.indexOf(targetColumnId);
    const columnIndex = idsArray.indexOf(columnIds[0]);

    idsArray.splice(targetIndex, 1, ...columnIds);

    if (targetIndex > columnIndex) {
      idsArray.splice(idsArray.indexOf(columnIds[0]), columnIds.length, targetColumnId);
    } else {
      idsArray.splice(idsArray.lastIndexOf(columnIds[0]), columnIds.length, targetColumnId);
    }

    const payload = {
      indicator_id: indicatorTableData?.indicator_id,
      request_body: {
        dimension_ids: idsArray,
        scenario_id: baseScenario?.id,
      },
    };

    dispatch(updateIndicatorData(payload)).then(() => {
      dispatch(
        FetchPlangPageIndicatorTableData({
          indicator_id: indicatorTableData?.indicator_id,
          parameters: {
            scenario_id: baseScenario?.id,
            format_excel_data: true,
          },
        }),
      );
    });
  };

  useEffect(() => {
    if (currentDriver?.id) {
      dispatch(
        fetchIndicatorDetail({
          currentDriver,
          parameters: {
            dim_id: indicatorTableData?.column_headers[0].is_dimension
              ? indicatorTableData?.column_headers[0].id
              : null,
            scenario_id: baseScenario?.id,
          },
        }),
      );
    }
  }, [indicatorTableData]);

  const headerRowTemplate = useCallback(() => new HeaderRow(), []);
  const textCellTemplate = useCallback(() => new TextCellTemplate(), []);

  const customCellTemplates = useCallback(
    () => ({
      header: headerRowTemplate(),
      text: textCellTemplate(),
    }),
    [],
  );

  useEffect(() => {
    if (tableReference.current && indicatorTableData?.column_headers) {
      const container = tableReference.current;
      const dates = indicatorTableData?.column_headers?.map((data: any) => data.name);
      const formattedDate = `${startDateObject.toLocaleString('en-US', {
        month: 'short',
      })}-${startDateObject.getFullYear().toString().slice(-2)}`;
      const index = dates.indexOf(formattedDate);
      const totalWidth = index * 125;
      container.scrollLeft = totalWidth - 125;
    }
  }, [indicatorTableData?.column_headers, rows, columns]);

  return (
    <Flex
      width={'100%'}
      ref={tableReference}
      className='md-block-table-container sm-scroll'
      maxH={
        isBuilderMode
          ? `calc(95vh - ${HEADER_HEIGHT_WITH_NAVIGATION} - 35vh)`
          : isSmallDevice
          ? `calc(88vh - ${HEADER_HEIGHT_WITH_NAVIGATION} - 35vh)`
          : isMobile
          ? `calc(92vh - ${HEADER_HEIGHT_WITH_NAVIGATION} - 35vh)`
          : `calc(95vh - ${HEADER_HEIGHT_WITH_NAVIGATION} - 35vh)`
      }
      overflow={rows?.length >= 2 ? 'auto' : 'none'}
      paddingRight={rows && rows?.length > 3 && columns && columns.length > 3 ? '0' : '1.3rem'}
    >
      {columns?.length > 0 && rows?.length > 0 && indicatorTableData && (
        <ReactGrid
          disableVirtualScrolling
          rows={rows}
          stickyTopRows={1}
          columns={columns}
          stickyLeftColumns={
            currentType === 'constant' ? 0 : indicatorTableData?.dimensions?.length
          }
          onCellsChanged={(changes: any) => updateCellData(changes)}
          onColumnResized={(ci, width) => handleColumnResize(ci, width, setColumns)}
          onColumnsReordered={handleColumnsReorder}
          enableColumnSelection
          enableRangeSelection
          enableFillHandle
          customCellTemplates={customCellTemplates()}
        ></ReactGrid>
      )}
    </Flex>
  );
};

export default MultiDimensionTable;
