import { AddIcon } from '@chakra-ui/icons';
import {
  Button,
  Divider,
  Flex,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
  Tooltip,
  useOutsideClick,
} from '@chakra-ui/react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import React, { useState } from 'react';

import CalculationIconComponent from 'components/Icons/CalculationIcon';
import ChevronDownIconComponent from 'components/Icons/ChevronDownIcon';
import DuplicateIconComponent from 'components/Icons/DuplicateIcon';
import InputIconComponent from 'components/Icons/InputIcon';
import InputPanelIconComponent from 'components/Icons/InputPanelIcon';
import PropertyViewIconComponent from 'components/Icons/PropertyViewIcon';
import SettingViewIconComponent from 'components/Icons/SettingsViewIcon';
import MapViewIconComponent from 'components/Icons/MapViewIconComponent';
import {
  DuplicateIndicator,
  FetchBlockOutputs,
  getBaseScenario,
  getPlannerBlock,
  getSelectedIndicators,
  getToggleInputPanel,
  getToolbarType,
  handleAddIndicator,
  handleToggleInputPanel,
  toggleToolbarType,
  UpdateIndicator,
  UpdateIndicatorFormat,
} from 'redux/PlannerModeSlice';
import { capitalizeFirstLetter } from 'utils/GlobalHelpers';

// import AutoComplete2 from './AutoComplete2';
import PlannerMenu from './Dropdown';
import DraftJSFormula from './DraftJSFormula';

const PlannerModeToolbar = ({ flags }: any) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { blockId } = useParams();
  const dispatch: any = useDispatch();
  const toolbarType = useSelector(getToolbarType);
  const baseScenario = useSelector(getBaseScenario);
  const plannerBlock = useSelector(getPlannerBlock);
  const toggleInputPanel = useSelector(getToggleInputPanel);
  const selectedIndicators = useSelector(getSelectedIndicators);

  const dataFormat = [
    { type: 'Number', label: '.00', value: 'number' },
    { type: 'Integer', label: '123', value: 'integer' },
    { type: 'Percentage', label: '%', value: 'percentage' },
    { type: 'Currency', label: '$', value: 'currency' },
  ];

  const getIndicatorData = () => {
    const idArray = plannerBlock?.dimensions
      .filter((item: any) => item.name !== 'Time')
      .map((item: any) => item.id);
    const dimensionIds = idArray.join(', ');
    const payload = {
      blockId,
      params: {
        dim_id: dimensionIds,
        indicator_filter: 'all',
        scenario_id: baseScenario?.id,
      },
    };
    dispatch(FetchBlockOutputs(payload));
  };

  const handleFieldChanges = (key: string, value: any) => {
    dispatch(
      UpdateIndicator({
        indicatorId: selectedIndicators?.id,
        data: {
          [key]: value,
        },
      }),
    );
  };

  const handleFormatChanges = (key: string, value: any) => {
    dispatch(
      UpdateIndicatorFormat({
        indicatorId: selectedIndicators?.id,
        data: {
          [key]: value,
        },
      }),
    ).then(() => {
      getIndicatorData();
    });
  };

  const handleDuplicateIndicator = (data: any) => {
    dispatch(
      DuplicateIndicator({
        indicatorId: data?.id,
        data: {
          scenario_id: baseScenario?.id,
        },
      }),
    ).then(() => {
      getIndicatorData();
    });
  };

  const handleToolbarChange = (data: string) => {
    dispatch(handleAddIndicator(false));
    dispatch(toggleToolbarType(data));
  };
  const reference = React.useRef() as React.MutableRefObject<HTMLInputElement>;

  useOutsideClick({
    ref: reference,
    handler: () => {
      setIsExpanded(false);
    },
  });

  return (
    <Flex
      bgColor={'rgb(61, 52, 121)'}
      borderRadius={'8px'}
      py={3}
      px={3}
      my={4}
      alignItems={'center'}
      justifyContent={selectedIndicators ? 'space-between' : 'end'}
      minH={'67.5px'}
      width={'100%'}
      transition='width 0.3s ease-in-out'
    >
      {selectedIndicators && (
        <Flex alignItems={'center'} width={'100%'} overflowX={flags?.formulaBar ? 'auto' : 'unset'}>
          <Menu variant={'secondary'}>
            {({ isOpen }) => (
              <>
                <MenuButton
                  width={'fit-content'}
                  as={Button}
                  minW={'fit-content'}
                  rightIcon={<ChevronDownIconComponent />}
                  leftIcon={
                    selectedIndicators?.indicator_type.toLowerCase() === 'calculation' ? (
                      <CalculationIconComponent color='#4BB6B9' />
                    ) : (
                      <InputIconComponent color='#4BB6B9' />
                    )
                  }
                  border='none'
                  padding={'0'}
                >
                  <Text variant={'h5'} mx={1} fontWeight='400'>
                    {selectedIndicators?.indicator_type.toLowerCase() === 'calculation'
                      ? 'Calculation'
                      : 'Input'}
                  </Text>
                </MenuButton>
                {isOpen && (
                  <MenuList>
                    <MenuOptionGroup
                      value={selectedIndicators?.indicator_type.toLowerCase()}
                      onChange={(role: any) => {
                        handleFieldChanges('type', role);
                      }}
                    >
                      <MenuItemOption
                        value={'calculation'}
                        backgroundColor={
                          selectedIndicators?.indicator_type.toLowerCase() === 'calculation'
                            ? '#EEF2F6'
                            : 'transparent'
                        }
                      >
                        <Flex>
                          <CalculationIconComponent
                            color={
                              selectedIndicators?.indicator_type.toLowerCase() === 'calculation'
                                ? '#6562CF'
                                : '#8B8AAB'
                            }
                          />
                          <Text ml={2}>Calculation</Text>
                        </Flex>
                      </MenuItemOption>
                      <MenuItemOption
                        value={'input'}
                        backgroundColor={
                          selectedIndicators?.indicator_type.toLowerCase() === 'input'
                            ? '#EEF2F6'
                            : 'transparent'
                        }
                      >
                        <Flex>
                          <InputIconComponent
                            color={
                              selectedIndicators?.indicator_type.toLowerCase() === 'input'
                                ? '#6562CF'
                                : '#8B8AAB'
                            }
                          />
                          <Text ml={2}>Input</Text>
                        </Flex>
                      </MenuItemOption>
                    </MenuOptionGroup>
                  </MenuList>
                )}
              </>
            )}
          </Menu>
          {selectedIndicators?.indicator_type.toLowerCase() === 'calculation' ? (
            <Flex width={'100%'} transition='width 0.3s ease-in-out' ref={reference}>
              <DraftJSFormula setIsExpanded={setIsExpanded} isExpanded={isExpanded} />
            </Flex>
          ) : (
            <Button
              ml={5}
              height={'fit-content'}
              width={'fit-content'}
              border={'none'}
              borderRadius={'8px'}
              leftIcon={
                <InputPanelIconComponent color={toggleInputPanel ? '#4BB6B9' : '#ffffff'} />
              }
              padding={'.7rem 1rem .7rem .3rem'}
              backgroundColor={'rgba(101, 98, 207, 40%)'}
              fontWeight={'400'}
              fontSize={'xs'}
              onClick={() => dispatch(handleToggleInputPanel())}
            >
              {toggleInputPanel ? 'Hide input panel' : 'Show input panel'}
            </Button>
          )}
        </Flex>
      )}
      <Flex
        display={isExpanded ? 'none' : 'flex'}
        alignItems={'center'}
        flex={selectedIndicators ? 1 : 0}
        justifyContent={'end'}
      >
        {selectedIndicators && (
          <>
            <PlannerMenu
              mr={'4'}
              payloadKey={'data_type'}
              buttonName={selectedIndicators?.data_type}
              rightIcon={<ChevronDownIconComponent />}
              handleChanges={(dataType: string, data: any) => handleFieldChanges(dataType, data)}
              menuItems={[
                { name: 'Number', value: 'number' },
                { name: 'Statistic', value: 'statistic' },
                { name: 'Percentage', value: 'percentage' },
                { name: 'Ratio', value: 'ratio' },
                { name: 'Balance', value: 'balance' },
                { name: 'Opening  balance', value: 'opening_balance' },
              ]}
            />
          </>
        )}
        {selectedIndicators && (
          <>
            {dataFormat?.map((item: any, index: number) => (
              <Tooltip placement='top' label={item?.type} key={index}>
                <Button
                  variant={'group'}
                  backgroundColor={
                    capitalizeFirstLetter(selectedIndicators?.format) === item?.type
                      ? '#6562CF66'
                      : 'transparent'
                  }
                  color={
                    capitalizeFirstLetter(selectedIndicators?.format) === item?.type
                      ? '#69B4B8'
                      : '#fff'
                  }
                  mx={'.2rem'}
                  onClick={() => handleFormatChanges('data_format', item?.value)}
                >
                  {item?.label}
                </Button>
              </Tooltip>
            ))}
            <Divider
              orientation='vertical'
              mx={6}
              height='25px'
              bgColor={'violet.850'}
              borderLeftWidth='1px'
            />
          </>
        )}
        <Flex justifyContent={'space-between'} alignItems={'center'} width={'120px'}>
          <SettingViewIconComponent
            type={toolbarType === 'Property' ? 'Property' : ''}
            onClick={() => {
              handleToolbarChange('Property');
              if (toggleInputPanel) {
                dispatch(handleToggleInputPanel());
              }
            }}
            style={{
              borderRadius: '8px',
              backgroundColor: toolbarType === 'Property' ? 'rgba(101, 98, 207, 40%)' : '',
              padding: toolbarType === 'Property' ? '.5rem' : '0rem',
            }}
          />
          <PropertyViewIconComponent
            type={toolbarType === 'Data' ? 'Data' : ''}
            onClick={() => {
              handleToolbarChange('Data');
              if (toggleInputPanel) {
                dispatch(handleToggleInputPanel());
              }
            }}
            style={{
              borderRadius: '8px',
              backgroundColor: toolbarType === 'Data' ? 'rgba(101, 98, 207, 40%)' : '',
              padding: toolbarType === 'Data' ? '.5rem' : '0rem',
            }}
          />

          <MapViewIconComponent
            type={toolbarType === 'Map' ? 'Map' : ''}
            onClick={() => {
              handleToolbarChange('Map');
            }}
            style={{
              borderRadius: '8px',
              backgroundColor: toolbarType === 'Map' ? 'rgba(101, 98, 207, 40%)' : '',
              padding: toolbarType === 'Map' ? '.5rem' : '.5rem',
            }}
          />
        </Flex>

        <Divider
          orientation='vertical'
          mx={5}
          height='25px'
          bgColor={'violet.850'}
          borderLeftWidth='1px'
        />
        <Tooltip placement='top' label={'Add indicator'}>
          <AddIcon mr={6} cursor={'pointer'} onClick={() => dispatch(handleAddIndicator(true))} />
        </Tooltip>
        {selectedIndicators && (
          <DuplicateIconComponent
            onClick={() => handleDuplicateIndicator(selectedIndicators)}
            style={{ marginRight: '1rem' }}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default withLDConsumer()(PlannerModeToolbar);
