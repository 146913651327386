/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import { BiFilter } from 'react-icons/bi';
import { Button, Flex, Text, Box, Menu, MenuButton, MenuList, Divider } from '@chakra-ui/react';
import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ChevronDownIcon } from '@chakra-ui/icons';

import { handleFilters } from 'redux/PlanPageSlice';
import {
  fetchDashboardChart,
  fetchDashboardGraph,
  getAllChartData,
  getModalData,
  handleAddAllChartData,
} from 'redux/DashboardSlice';
import PreviewSelectCheckBox from 'components/PreviewFilterCheckBox/MultiSelectCheckBox';
import { getAllScenarios } from 'redux/ScenarioSlice';

interface Properties {
  filterChartData: any;
  block_id: any;
  graphData?: any;
}

const PriviewFilterByComponent = ({ filterChartData, graphData, block_id }: Properties) => {
  const dispatch: any = useDispatch();
  const allChartData = useSelector(getAllChartData);
  const allScenarios = useSelector(getAllScenarios);
  const scenarioId = allScenarios.find((item: any) => item.is_base)?.id;
  const modalData = useSelector(getModalData);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [filterItem, setFilterItem] = useState<any>([]);

  const blockDimension = useMemo(() => {
    const blockDimensionData = modalData?.find((item: any) => item.block_id === block_id);
    const dimensions = blockDimensionData?.dimensions;
    return dimensions;
  }, [modalData]);

  const kpiDimension = useMemo(() => {
    const dim = filterChartData?.instrument_filters
      ?.filter((item: any) => item?.filter_type === 'dimension')
      ?.map((item: any) => item?.name);
    return dim;
  }, [filterChartData?.instrument_filters]);

  const filterDimenesion = useMemo(() => {
    const filterDimData = blockDimension?.filter((item: any) =>
      kpiDimension?.includes(item?.dimension_name),
    );

    const matchingCharts = filterChartData?.instrument_filters?.filter(
      (chart: any) => chart?.filter_type === 'dimension',
    );

    const updatedDimensions = filterDimData?.map((dimension: any) => {
      const matchingId = matchingCharts?.find((id: any) => id.name === dimension.dimension_name);
      return {
        ...dimension,
        id: matchingId ? matchingId.id : null,
        instrument_id: matchingId ? matchingId.instrument_id : null,
      };
    });

    const resultArray = filterChartData?.instrument_filters
      ?.filter((item: any) => item.filter_type === 'dimension')
      .map((item: any) => {
        const dimItems = updatedDimensions?.find((d: any) => d.id === item.id);
        return {
          item_name: `${item.value}`,
          id: `${item.id}`,
          dim_name: `${item.name}`,
        };
      });

    const dummyArray = new Map(resultArray?.map((item: any) => [item.item_name, item]));
    const orderedArray: {}[] = [];

    updatedDimensions?.forEach((object1: any) => {
      object1.dimension_items?.forEach((item1: any) => {
        const match = dummyArray?.get(item1.dimension_item_name);
        if (match) {
          orderedArray.push(match);
        }
      });
    });
    setFilterItem(orderedArray);
    return updatedDimensions;
  }, [filterChartData?.instrument_filters, blockDimension]);

  const handleApplyFilter = () => {
    dispatch(handleFilters());
    dispatch(
      fetchDashboardGraph({ chartId: filterChartData?.id, scenarioId, requestBody: [] }),
    ).then((data: any) => {
      const updatedData = allChartData[filterChartData?.id];
      const layoutData = {
        id: updatedData.id,
        x: Number(updatedData.x),
        y: Number(updatedData.y),
        h: updatedData.h || 2,
        w: updatedData.w || 2,
        instrument_type: updatedData.instrument_type,
      };
      dispatch(handleAddAllChartData({ ...data.payload, ...layoutData }));
      dispatch(fetchDashboardChart(filterChartData?.id));
    });
    setIsMenuOpen(false);
  };

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  };

  const handleOpenMenu = () => {
    setIsMenuOpen(true);
  };

  return (
    <Menu
      variant={'filter'}
      isOpen={isMenuOpen}
      onClose={() => setIsMenuOpen(false)}
      onOpen={() => setIsMenuOpen(true)}
      strategy='fixed'
      direction={'ltr'}
    >
      <MenuButton>
        <Flex
          alignItems={'center'}
          bgColor='#f5f5f5'
          borderRadius='8px'
          p='0.1rem .1rem'
          maxWidth='100%'
        >
          <BiFilter size={35} color={'#4BB6B9'} />
          <Text size={'Roboto-12'} mr={2}>
            Filter by:
          </Text>
          {filterChartData?.instrument_type === 'line' ||
          filterChartData?.instrument_type === 'bar' ? (
            <>
              <Divider
                mx={2}
                orientation='vertical'
                size='2xs'
                sx={{
                  borderColor: '#706B9A',
                }}
                height='12px'
                alignSelf='center'
              />
              <Text size='Roboto-12' whiteSpace='nowrap'>
                All items
              </Text>
            </>
          ) : (
            filterItem &&
            filterItem.map((item: any, index: number) => (
              <React.Fragment key={index}>
                {item && (
                  <>
                    <Divider
                      mx={2}
                      orientation='vertical'
                      size='2xs'
                      sx={{
                        borderColor: '#706B9A',
                      }}
                      height='12px'
                      alignSelf='center'
                    />
                    <Text size='Roboto-12' whiteSpace='nowrap'>
                      {item.item_name}
                    </Text>
                  </>
                )}
              </React.Fragment>
            ))
          )}

          <Box>
            <ChevronDownIcon fontSize='md' />
          </Box>
        </Flex>
      </MenuButton>
      {/* {isOpen && ( */}
      <MenuList>
        <Flex gap={8} maxW={'600px'} overflowX={'auto'} className='sm-scroll'>
          {filterDimenesion?.map((checkBoxItem: any, index: number) => (
            <React.Fragment key={index}>
              <PreviewSelectCheckBox
                pieGraphData={graphData}
                handleApply={handleApplyFilter}
                handleClose={handleCloseMenu}
                checkboxData={checkBoxItem}
                isMenuOpen={isMenuOpen}
                dimItems={filterItem[index]}
                chartType={filterChartData?.instrument_type}
              />
            </React.Fragment>
          ))}
        </Flex>
      </MenuList>
    </Menu>
  );
};

export default PriviewFilterByComponent;
