/* eslint-disable no-lonely-if */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unsafe-optional-chaining */
import { Box, Flex, Text } from '@chakra-ui/react';
import { ReactGrid } from '@silevis/reactgrid';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './Table.scss';

import { AddRowCellTemplate } from 'components/AddRowCell';
import { DateCellTemplate } from 'components/DateCell';
import DeleteGroupModal from 'components/DeleteGroupModal';
import { DeleteRowCellTemplate } from 'components/DeleteRowCell';
import { DropdownCellTemplate } from 'components/DropdownCell';
import { FiltersCellTemplate } from 'components/FiltersCell';
import FullScreenLoader from 'components/FullScreenLoader';
import { HeaderRowTemplate } from 'components/HeaderRow';
import { TextCellTemplate } from 'components/TextCell';
import PlanPermission from 'container/PlanPermission';
import {
  DeleteDimensionHeader,
  DeleteDimensionProperty,
  FetchDimensionGroup,
  FetchDimensionItems,
  FetchDimensionProperties,
  ReorderDimensionProperty,
  UpdateDimensionPropertyBulk,
  UpdateDimensionPropertyName,
  UpdateDimensionValues,
  UpdatePropertyHeader,
  getAddItem,
  getBaseScenario,
  getDimensionProperty,
  getFilteredDimensionItems,
  getLoaderStatus,
  getPropertiesSectionStatus,
  getSelectedDimension,
  getSettingsSectionStatus,
  handleAddItemStatus,
  handleDimensionItemsChange,
  resetBuilderDimension,
  UpdateDimensionName,
  handleDimensionLoader,
  getDimensionLoaderStatus,
  DimensionTableReordering,
} from 'redux/BuilderModeSlice';
import { hasEndDate, hasStartDate } from 'utils/GlobalHelpers';
import { AddItemProperty, getDimensionFilters, handleDimFiltersChange } from 'redux/ItemPageSlice';
import { SelectCellTemplate } from 'components/SelectCell';

import { getTableColumns, getTableRows, reorderArray } from './NewTableHelpers';
import DeleteTable from './DeleteTable';
import { getColumns, getRows } from './TableHelpers';

interface Focus {
  rowId: number | string;
  columnId: number | string;
}

const swapProperties = (columns: any, target: any, columnIds: any) => {
  const targetId = Number.parseInt(target, 10);
  // Convert columnIds to numbers for comparison
  const idsToSwap = columnIds.map((id: any) => Number.parseInt(id, 10));

  const targetIndex = columns.findIndex((column: any) => column.id === targetId);

  idsToSwap.forEach((id: any) => {
    const swapIndex = columns.findIndex(
      (column: any) => Number.parseInt(column.id, 10) === Number.parseInt(id, 10),
    );
    if (targetIndex !== -1 && swapIndex !== -1) {
      const temporary = columns[targetIndex];
      columns[targetIndex] = columns[swapIndex];
      columns[swapIndex] = temporary;
    }
  });
  return columns;
};

const NewDimensionBuilderTable = ({ planPermission }: any) => {
  const dispatch: any = useDispatch();
  const selectedDimension: any = useSelector(getSelectedDimension);
  const selectedScenario = useSelector(getBaseScenario);
  const filteredDimensionItem: any = useSelector(getFilteredDimensionItems);
  const dimProperties: any = useSelector(getDimensionProperty);
  const loaderStatus: any = useSelector(getLoaderStatus);
  const addItem: any = useSelector(getAddItem);
  const tableReference: any = useRef(null);
  const propertiesSectionStatus = useSelector(getPropertiesSectionStatus);
  const settingsSectionStatus = useSelector(getSettingsSectionStatus);
  const dimensionFilters = useSelector(getDimensionFilters);
  const dimensionLoader = useSelector(getDimensionLoaderStatus);
  const [dimensionProperties, setDimensionProperties] = useState<any>([]);

  useEffect(() => {
    if (dimProperties) {
      setDimensionProperties(dimProperties);
    }
  }, [dimProperties]);

  const [, setFocus] = useState<Focus>({ rowId: 'header', columnId: 'name' });
  const [tableData, setTableData]: any = useState();
  useEffect(() => {
    const getTableData = async () => {
      if (addItem) {
        dispatch(handleAddItemStatus(false));
      }
      if (selectedDimension && selectedScenario) {
        dispatch(handleDimensionLoader(true));
        await dispatch(
          FetchDimensionProperties({
            selectedId: selectedDimension?.id,
            parameters: { scenario_id: selectedScenario?.id },
          }),
        ).then((response: any) => {
          if (response?.payload?.properties) {
            response?.payload?.properties?.map((data: any) => {
              if (data?.data_format) {
                dispatch(
                  FetchDimensionGroup({
                    data,
                    parameters: { scenario_id: selectedScenario?.id },
                  }),
                );
              }
              return null;
            });
          }
        });
        await dispatch(
          FetchDimensionItems({
            selectedId: selectedDimension?.id,
            parameters: { scenario_id: selectedScenario?.id },
          }),
        );
        dispatch(handleDimensionLoader(false));
      }
    };
    getTableData();
    setFocus({ rowId: 'header', columnId: 'name' });
  }, [selectedDimension, selectedScenario]);

  const rows: any = useMemo(() => {
    if (dimensionProperties?.length >= 0 && tableData?.length >= 0) {
      return selectedDimension?.name !== 'Time'
        ? getTableRows(tableData, dimensionProperties, selectedDimension)
        : getRows(tableData, dimensionProperties);
    }
    return null;
  }, [tableData, dimensionProperties]);

  const memorizedColumns = useMemo(() => {
    if (dimensionProperties?.length >= 0 && tableReference?.current?.offsetWidth) {
      return selectedDimension?.name !== 'Time'
        ? getTableColumns(
            dimensionProperties,
            tableReference?.current?.offsetWidth,
            selectedDimension,
          )
        : getColumns(dimensionProperties, tableReference?.current?.offsetWidth);
    }
    return null;
  }, [dimensionProperties, selectedDimension]);

  // It updates dimension properties to enable start and end date toggle
  function updateDimensionStartEnd(enable: boolean) {
    dispatch(
      UpdateDimensionName({
        item_id: selectedDimension?.id,
        request_body: {
          enable_start_end: enable ? 'true' : 'false',
        },
      }),
    );
  }

  // To check if dimensionProperties has start & end date, so enable start and end date toggle in
  useEffect(() => {
    if (dimensionProperties?.length > 0) {
      const startDate = dimensionProperties.some((item: any) => hasStartDate(item?.name));
      const endDate = dimensionProperties.some((item: any) => hasEndDate(item?.name));
      const shouldEnable = startDate || endDate;

      if (shouldEnable !== selectedDimension?.enable_start_end) {
        updateDimensionStartEnd(shouldEnable);
      }
    }
  }, [dimensionProperties]);

  const [columns, setColumns]: any = useState(memorizedColumns);
  const [filtersData, setFiltersData]: any = useState();
  const [gridProperties, setGridProperties] = useState({});
  const [deleteModal, setDeleteModal] = useState({
    itemId: '',
    itemName: '',
    type: '',
    status: false,
  });

  useEffect(() => {
    if (tableReference?.current?.offsetWidth && dimensionProperties?.length >= 0) {
      if (selectedDimension?.name !== 'Time') {
        setColumns(
          getTableColumns(
            dimensionProperties,
            tableReference?.current?.offsetWidth,
            selectedDimension,
          ),
        );
      } else {
        setColumns(getColumns(dimensionProperties, tableReference?.current?.offsetWidth));
      }
    }
  }, [propertiesSectionStatus, settingsSectionStatus]);

  useEffect(() => {
    if (filtersData) {
      dispatch(
        handleDimensionItemsChange({
          datas: filtersData?.data,
          cell: filtersData?.cell,
          dimensionFilters,
        }),
      );
    }
  }, [filtersData]);

  useEffect(() => {
    if (filteredDimensionItem?.length >= 0) setTableData(filteredDimensionItem);
  }, [filteredDimensionItem]);

  useEffect(() => {
    if (memorizedColumns) setColumns(memorizedColumns);
  }, [memorizedColumns]);

  const handleAddRow = () => {
    let object: any = {};
    for (const number of dimensionProperties) {
      object[number?.id] = '';
    }
    object = { ...object, name: '', id: '', position: '' };
    setTableData([...tableData, object]);
    setFocus({ rowId: rows?.length - 2, columnId: 'name' });
    setGridProperties({ focusLocation: { rowId: rows?.length - 2, columnId: 'name' } });
  };

  useEffect(() => {
    if (addItem) handleAddRow();
  }, [addItem]);

  useEffect(() => {
    return () => {
      setFocus({ rowId: 'header', columnId: 'name' });
      dispatch(resetBuilderDimension());
    };
  }, []);

  const updateHeading = async (data: any) => {
    await dispatch(
      UpdatePropertyHeader({
        propertyId: data?.columnId,
        data: {
          name: data?.newCell?.text,
        },
      }),
    ).then(() => {
      dispatch(
        FetchDimensionProperties({
          selectedId: selectedDimension?.id,
          parameters: { scenario_id: selectedScenario?.id },
        }),
      );
    });
  };

  const updateCell = async (changes: any) => {
    const change = changes[0];
    const existingRow = rows?.filter((data: any) => {
      return change?.rowId === data?.rowId;
    });
    if (change?.newCell?.colType && change?.previousCell?.colType) {
      updateHeading(change);
    } else if (
      change?.newCell?.property &&
      !change?.previousCell?.text &&
      !existingRow[0]?.itemId
    ) {
      await dispatch(
        AddItemProperty({
          dimension_id: Number(selectedDimension?.id),
          request_body: {
            name: change?.newCell?.text,
            scenario_id: selectedScenario?.id,
          },
          toaster: true,
        }),
      );
      await dispatch(
        FetchDimensionItems({
          selectedId: selectedDimension?.id,
          parameters: { scenario_id: selectedScenario?.id },
        }),
      );
    } else {
      if (change?.newCell?.property) {
        await dispatch(
          UpdateDimensionPropertyName({
            item_id: filteredDimensionItem[change?.rowId]?.id,
            name: change?.newCell?.text,
            toaster: true,
          }),
        );
        await dispatch(
          FetchDimensionItems({
            selectedId: selectedDimension?.id,
            parameters: { scenario_id: selectedScenario?.id },
          }),
        );
      } else {
        await dispatch(
          UpdateDimensionValues({
            item_id: filteredDimensionItem[change?.rowId]?.id,
            request_body: {
              property_id: change?.columnId,
              value: change?.newCell?.text,
              scenario_id: selectedScenario?.id,
            },
            toaster: true,
          }),
        );
        await dispatch(
          FetchDimensionItems({
            selectedId: selectedDimension?.id,
            parameters: { scenario_id: selectedScenario?.id },
          }),
        );
      }
    }
  };

  const updateCellBulk = async (changes: any) => {
    const newChanges: any[] = [];
    for (const change of changes) {
      const object = {
        item_id: `${filteredDimensionItem[change?.rowId]?.id}`,
        property_id: `${change?.columnId}`,
        value: change?.newCell?.text,
      };
      newChanges.push(object);
    }
    if (newChanges?.length > 0)
      await dispatch(
        UpdateDimensionPropertyBulk({
          request_body: {
            items: newChanges,
            dimension_id: selectedDimension?.id,
            scenario_id: selectedScenario?.id,
          },
          toaster: true,
        }),
      );
  };

  const updateCells = async (changes: any) => {
    if (addItem) dispatch(handleAddItemStatus(false));
    await (changes?.length > 1 ? updateCellBulk(changes) : updateCell(changes));
    const dimensionFilter = JSON.parse(JSON.stringify(dimensionFilters));
    if (dimensionFilters?.length > 0) {
      for (let index = 0; index < dimensionFilter?.length; index += 1) {
        if (dimensionFilter[index].id === changes[0].columnId) {
          const updatedValue = { name: changes[0]?.newCell?.text, status: true };
          dimensionFilter[index].data = [...dimensionFilter[index]?.data, updatedValue];
        }
      }
      dispatch(
        handleDimensionItemsChange({
          datas: filtersData?.data,
          cell: filtersData?.cell,
          dimensionFilters: dimensionFilter,
        }),
      );

      for (let index = 0; index < dimensionFilter?.length; index += 1) {
        const isCheckedData = dimensionFilter?.map((data: any) => data?.data);
        const isCheckedId = dimensionFilter?.map((data: any) => data?.id);
        dispatch(
          handleDimFiltersChange({
            ischeckedItemsChecked: isCheckedData[index],
            id: isCheckedId[index],
          }),
        );
      }
    }
  };

  const handleLocationChanged = (event: any) => {
    if (Object.keys(gridProperties)?.length > 0) {
      setGridProperties({});
    }
    setFocus(event);
    return true;
  };

  const handleColumnResize = (ci: any, width: number) => {
    setColumns((column: any) => {
      const columnIndex = column.findIndex((element: any) => element.columnId === ci);
      const resizedColumn = column[columnIndex];
      const updatedColumn = { ...resizedColumn, width };
      column[columnIndex] = updatedColumn;
      return [...column];
    });
  };

  const handleDeleteRow = async (data: any) => {
    if (addItem) {
      dispatch(handleAddItemStatus(false));
    }
    if (data?.id === '') {
      dispatch(
        FetchDimensionItems({
          selectedId: selectedDimension?.id,
          parameters: { scenario_id: selectedScenario?.id },
        }),
      );
    } else {
      setDeleteModal({ itemId: data?.id, itemName: data?.itemName, type: 'row', status: true });
    }
  };

  const handleDeleteModal = async (type: string) => {
    if (type === 'header') {
      setDeleteModal({ itemId: '', itemName: '', type: '', status: false });
      dispatch(DeleteDimensionHeader({ item_id: deleteModal?.itemId })).then(() => {
        dispatch(
          FetchDimensionProperties({
            selectedId: selectedDimension?.id,
            parameters: { scenario_id: selectedScenario?.id },
          }),
        );
      });
    } else {
      if (deleteModal?.itemId) {
        setDeleteModal({ itemId: '', itemName: '', type: '', status: false });
        await dispatch(
          DeleteDimensionProperty({
            item_id: deleteModal?.itemId,
            parameters: { scenario_id: selectedScenario?.id },
          }),
        );
        dispatch(
          FetchDimensionItems({
            selectedId: selectedDimension?.id,
            parameters: { scenario_id: selectedScenario?.id },
          }),
        );
      }
    }
  };

  const handleContextMenu = (
    selectedRowIds: any,
    selectedColIds: any,
    selectionMode: any,
    menuOptions: any,
    selectedRanges: any,
  ) => {
    const deleteRow = {
      id: 'deleteRow',
      label: 'Delete row',
      handler: () => {
        const filteredRows = rows?.filter((data2: any) => {
          return data2?.rowId === selectedRanges[0][0]?.rowId && data2;
        });
        handleDeleteRow({ id: filteredRows[0]?.itemId, itemName: filteredRows[0]?.itemName });
      },
    };

    const deleteColumn = {
      id: 'deleteRow',
      label: 'Delete Column',
      handler: () => {
        const filteredColumn = columns?.filter((data2: any) => {
          return data2?.columnId === selectedRanges[0][0]?.columnId && data2;
        });
        setDeleteModal({
          itemId: filteredColumn[0]?.columnId,
          itemName: filteredColumn[0]?.name,
          type: 'header',
          status: true,
        });
      },
    };
    if (
      selectedRanges[0] &&
      selectedRanges[0][0]?.rowId === 'header' &&
      selectedRanges[0][0]?.columnId !== 'name'
    ) {
      return [deleteColumn];
    }
    if (
      selectedRanges[0] &&
      selectedRanges[0][0]?.rowId !== 'header' &&
      selectedRanges[0][0]?.rowId !== 'footer'
    ) {
      let selectedRows = false;
      for (let index = 0; index < selectedRanges[0]?.length; index += 1) {
        selectedRows = selectedRanges[0][0]?.rowId === selectedRanges[0][index]?.rowId;
      }
      if (
        selectedRanges[0][0]?.columnId !== 'delete' &&
        selectedRows &&
        selectedDimension?.name !== 'Time'
      ) {
        menuOptions = menuOptions.filter((object: any) => object.id !== 'cut');
        return [...menuOptions, deleteRow];
      }
      return selectedRanges[0][0]?.columnId === 'delete' &&
        selectedRanges[0]?.length === 1 &&
        selectedDimension?.name !== 'Time'
        ? [deleteRow]
        : menuOptions.filter((object: any) => object.id !== 'cut');
    }
    return [];
  };

  const handleCanReorderRows = (targetRowId: any) => {
    return targetRowId !== 'footer' || targetRowId !== 'header';
  };
  const canReorderColumns = (targetColumnId: any) => {
    return targetColumnId !== 'name' || targetColumnId !== 'select';
  };

  const handleColumnsReorder = async (targetColumnId: any, columnIds: any) => {
    if (dimensionProperties?.length) {
      const properties = swapProperties(
        JSON.parse(JSON.stringify(dimensionProperties)),
        targetColumnId,
        columnIds,
      );

      setDimensionProperties([...properties]);

      const reorderedColumsn = properties
        .filter((col: any) => {
          return !Number.isNaN(Number.parseInt(col.id, 10));
        })
        .map((col: any) => {
          return Number.parseInt(col.id, 10);
        });

      dispatch(
        DimensionTableReordering({
          dimId: selectedDimension?.id,
          request_body: { dim_item_prop: reorderedColumsn },
        }),
      );
    }
  };

  const handleRowsReorder = async (targetRowId: any, rowIds: any[]) => {
    const to = tableData?.findIndex((person: any) => person.position - 1 === targetRowId);
    const rowsIds = rowIds.map((id) =>
      tableData?.findIndex((person: any) => person.position - 1 === id),
    );
    const reorededArray = reorderArray(tableData, rowsIds, to);
    await dispatch(
      ReorderDimensionProperty({
        selectedDimension: selectedDimension?.id,
        reorededArr: reorededArray,
      }),
    );
    dispatch(
      FetchDimensionItems({
        selectedId: selectedDimension?.id,
        parameters: { scenario_id: selectedScenario?.id },
      }),
    );
  };

  const handleDeleteHeader = (data: any) =>
    setDeleteModal({ itemId: data?.id, itemName: data?.text, type: 'header', status: true });

  const handleFilterChange = (cell: any, data: any) => setFiltersData({ cell, data });

  return (
    <Flex
      height={'100%'}
      width={'100%'}
      flexDirection={'column'}
      ref={tableReference}
      flex={1}
      overflow={'hidden'}
      position={'relative'}
    >
      <Flex justifyContent={'space-between'} alignItems={'center'} position={'absolute'}>
        <Text fontSize={'md'} width={'fit-content'} mb={'.4rem'}>
          Items
        </Text>
      </Flex>
      {dimensionLoader || loaderStatus ? (
        <FullScreenLoader height='inherit' bgColor='transparent' />
      ) : (
        <>
          {columns?.length && rows?.length ? (
            <>
              <DeleteTable />
              <Box
                className='md-table-container sm-scroll'
                width={'100%'}
                margin={'initial'}
                maxH={'initial'}
                mt={4}
                overflowY={rows?.length > 6 ? 'auto' : 'initial'}
              >
                <ReactGrid
                  rows={rows}
                  columns={columns}
                  enableFillHandle
                  enableRangeSelection
                  enableColumnSelection={true}
                  stickyRightColumns={columns?.length <= 2 ? 0 : 1}
                  stickyLeftColumns={columns?.length <= 2 ? 0 : 1}
                  stickyBottomRows={rows?.length <= 3 ? 0 : 1}
                  stickyTopRows={1}
                  enableRowSelection={rows?.length > 3}
                  verticalStickyBreakpoint={100}
                  horizontalStickyBreakpoint={100}
                  onRowsReordered={handleRowsReorder}
                  {...gridProperties}
                  onFocusLocationChanging={handleLocationChanged}
                  onCellsChanged={planPermission ? undefined : updateCells}
                  onContextMenu={planPermission ? undefined : handleContextMenu}
                  onColumnResized={planPermission ? undefined : handleColumnResize}
                  canReorderRows={handleCanReorderRows}
                  canReorderColumns={canReorderColumns}
                  onColumnsReordered={handleColumnsReorder}
                  customCellTemplates={{
                    header: new HeaderRowTemplate(),
                    delete: new DeleteRowCellTemplate(),
                    text: new TextCellTemplate(handleDeleteHeader),
                    date: new DateCellTemplate(),
                    addRow: new AddRowCellTemplate(handleAddRow, planPermission),
                    dropdown: new DropdownCellTemplate(handleDeleteRow, planPermission),
                    filter: new FiltersCellTemplate(handleFilterChange),
                    select: new SelectCellTemplate(),
                  }}
                />
              </Box>
            </>
          ) : (
            'NA'
          )}
        </>
      )}
      {deleteModal.status && (
        <DeleteGroupModal
          isOpen={deleteModal.status}
          onClose={() => setDeleteModal({ itemId: '', itemName: '', type: '', status: false })}
          itemName={deleteModal.itemName || 'some name'}
          onDelete={() => handleDeleteModal(deleteModal.type)}
          orgChart={true}
        />
      )}
    </Flex>
  );
};

export default PlanPermission(NewDimensionBuilderTable);
